import { ArrowLeftOutlined, UserOutlined } from '@ant-design/icons';
import { Col, Flex, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { getSupplierUserName } from '../../helpers/nUtils';
import { RoutesPath } from '../../routes/routesPath';
import { AwardListPage } from '../AwardSummary/AwardList';
import * as S from './styles';
import { AwardListProps } from './types';

export function AwardSummarySupplierViewPage() {
  const location = useLocation();
  const history = useHistory();
  const { bidId, bidName } = location.state as AwardListProps;
  const { t } = useTranslation();
  return (
    <>
      <S.BackButton
        onClick={() => history.push(RoutesPath.bidSupplierView, { bidId })}
        variant="slim"
      >
        <ArrowLeftOutlined />
        {t('pages.awardsSummary.bidSummaryBack')}
      </S.BackButton>
      <Flex justify="space-between" align="center">
        <Col>
          <Row align="middle" style={{ gap: 4 }}>
            <S.SpanTile>{t('pages.awardsSummary.title')}</S.SpanTile>
            <S.BidComponents>| {bidName}</S.BidComponents>
          </Row>
        </Col>
        <Col>
          <Row align="middle" style={{ gap: 4 }}>
            <S.SupplierSpan>{getSupplierUserName()?.supplier || '-'}</S.SupplierSpan>
            <S.UserContainer>
              <UserOutlined />
              <span>{getSupplierUserName()?.user || '-'}</span>
            </S.UserContainer>
          </Row>
        </Col>
      </Flex>
      <AwardListPage supplierView IdBid={bidId} />
    </>
  );
}
