import { useState } from 'react';

export function useModalSendAward() {
  const [message, setMessage] = useState('');

  const handleClearMessage = () => {
    setMessage('');
  };

  const handleValidateMessage = () => {
    return !message || message.length < 2;
  };

  return {
    setMessage,
    handleValidateMessage,
    handleClearMessage,
    message,
  };
}
