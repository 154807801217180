import { Flex } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  width: 33rem;

  @media (max-width: 1420px) {
    width: 100%;
  }
`;

export const TitleContainer = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  line-height: 2rem;
  b {
    font-size: 1.25rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }
  span {
    font-size: 1.25rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
  }
`;

export const Content = styled(Flex)`
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  padding: 1.5rem;
  height: 28rem;
  width: 100%;
`;

export const Color = styled.div<{ color?: string }>`
  width: 0.25rem;
  height: 4rem;
  border-radius: 0.25rem;
  background-color: ${({ color }) => color};
`;

export const Supplier = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const Demand = styled.span`
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.backText};
`;

export const Percentage = styled.span`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.tertiaryText};
`;
