import { Divider, Flex, Radio } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  width: 50.25rem;
  flex: 1;
`;

export const TitleContainer = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};

  b {
    font-size: 1.25rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }

  .usd {
    font-size: 1.25rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
  }

  .ant-radio-group {
    font-size: 0.875rem;
  }
`;

export const Content = styled(Flex)`
  justify-content: center;
  padding: 1.5rem;
  height: 28rem;
`;

export const TooltipContainer = styled.div`
  padding: 1rem;
  width: 14.75rem;
  background-color: ${({ theme }) => theme.colors.white};
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
  border-radius: 0.975rem;
`;

export const AltGroup = styled.span`
  font-size: 0.75rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
  margin-bottom: 0.5rem;
`;

export const Supplier = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const Percentage = styled.span`
  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const Value = styled.span<{ alignRight?: boolean }>`
  font-size: 0.75rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  text-align: ${({ alignRight }) => alignRight && 'right'};
`;

export const StyledDivider = styled(Divider)`
  margin: 0.5rem 0;
`;

export const StyledRadio = styled(Radio)`
  border-radius: 0.375rem;
  width: 6rem;
`;

export const LegendContainer = styled(Flex)`
  gap: 0.5rem;
  margin-top: 1.5rem;
`;
export const Legend = styled.div<{ color?: string }>`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  background-color: ${({ theme, color }) => color || theme.colorsDesignSystem.text};
`;
