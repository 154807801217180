import styled from 'styled-components';

interface PaddingAndBoarder {
  headerNoPadding: boolean;
  noBorder: boolean;
  scrollable: boolean;
  striped?: boolean;
}

export const TableContainer = styled.div<PaddingAndBoarder>`
  margin-bottom: 1.5rem;

  tr:nth-child(odd) {
    background-color: ${({ theme, striped }) =>
      !striped ? null : theme.colorsDesignSystem.table.darkRow};
  }

  tr:nth-child(odd) > td.ant-table-cell-fix-left {
    background-color: ${({ theme, striped }) =>
      !striped ? null : theme.colorsDesignSystem.table.darkRow};
  }

  td.ant-table-column-sort {
    background-color: transparent;
  }

  .ant-table-cell {
    padding: ${({ headerNoPadding }) => (headerNoPadding ? '0 !important' : 'none')};
  }

  .ant-table-wrapper .ant-table {
    border: 1px solid ${({ theme }) => theme.colorsDesignSystem.table.border};
    border-radius: 0.375rem;
    scrollbar-color: auto;
  }

  .ant-table-wrapper .ant-table-container table > thead > tr > th {
    border-bottom: 2px solid ${({ theme }) => theme.colorsDesignSystem.table.border};
  }

  .ant-table-wrapper .ant-table-container table > thead > tr > th:first-child {
    padding-left: 1rem;
  }
  .ant-table-wrapper .ant-table-container table > thead > tr > th:last-child {
    padding-right: 1rem;
  }
  .ant-table-body {
    scrollbar-width: auto;
    scrollbar-color: auto;
  }

  .ant-table-wrapper .ant-table-thead > tr:not(:last-child) > th[colspan] {
    border-bottom: none;
  }

  .border-right {
    border-right: 1px solid ${({ theme }) => theme.colorsDesignSystem.table.border};
  }

  .border-left {
    border-left: 1px solid ${({ theme }) => theme.colorsDesignSystem.table.border};
  }

  .remove-tip {
    &::before {
      display: none;
    }
  }

  .highlighted-column {
    background-color: ${({ theme }) =>
      theme.colorsDesignSystem.newTablesColors.colorBackgroundSubItens} !important;
  }
`;
