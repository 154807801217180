import { Divider, Empty, Flex } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Label, Pie, PieChart } from 'recharts';
import { StyledLoading } from '../../../../components/Common/StyledLoading';
import { handleFormatValueFields } from '../../../../helpers/nUtils';
import theme from '../../../../styles/theme';
import { IParams } from '../types';
import * as S from './styles';
import { useDemandsWin } from './useDemandsWin';

export function DemandsWin({ altGroup, category, supplier, idBid }: IParams) {
  const { t } = useTranslation();
  const { fetchGraphData, graphData, isLoading } = useDemandsWin();

  useEffect(() => {
    fetchGraphData({ altGroup, category, supplier, idBid });
  }, [altGroup, category, supplier]);

  return (
    <S.Container>
      <S.TitleContainer>
        <b>{t('pages.awards.managementBid.demandWin.title')}</b>
        <Divider type="vertical" />
        <span>{t('pages.awards.managementBid.demandWin.qty')}</span>
      </S.TitleContainer>
      <S.Content>
        {isLoading ? (
          <StyledLoading height={10} />
        ) : graphData ? (
          <>
            <PieChart width={250} height={250}>
              <Pie data={graphData?.demandsWin} dataKey="demand" outerRadius={100} innerRadius={70}>
                <Label
                  position="center"
                  value={handleFormatValueFields({
                    number: graphData?.totaldemand,
                    minFractional: 0,
                    maxFactional: 0,
                  })}
                  style={{
                    color: `${theme.colorsDesignSystem.text}`,
                    fontWeight: 700,
                    fontSize: '2rem',
                  }}
                />
              </Pie>
            </PieChart>
            <Flex vertical gap={24}>
              {graphData?.demandsWin.map((item) => {
                return (
                  <Flex key={item.supplier} gap={8}>
                    <S.Color color={item.fill} />
                    <Flex vertical gap={4}>
                      <S.Supplier>{item.supplier}</S.Supplier>
                      <Flex align="center" gap={8}>
                        <S.Demand>
                          {handleFormatValueFields({
                            number: item.demand,
                            maxFactional: 0,
                            minFractional: 0,
                          })}
                        </S.Demand>
                        <S.Percentage>
                          {handleFormatValueFields({ number: item.percentage })}%
                        </S.Percentage>
                      </Flex>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
          </>
        ) : (
          <Empty />
        )}
      </S.Content>
    </S.Container>
  );
}
