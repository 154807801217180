import { Radio } from 'antd';
import styled from 'styled-components';

export const Container = styled.main`
  margin-top: 1.5rem;
  height: auto;
`;

export const StyleRadio = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
  }
`;
export const TablesContainer = styled.div`
  display: flex;
  margin-top: 1.5rem;
  gap: 1.5rem;
`;

export const FirstTable = styled.div`
  box-sizing: border-box;
  width: 70%;
`;

export const SecondTable = styled.div`
  box-sizing: border-box;
  width: 30%;
`;

export const AltGroupLink = styled.span`
  padding: 0 0.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  cursor: pointer;
`;

export const TotalValues = styled.span`
  font-weight: 600;
  padding: 0 0.5rem;
`;
export const HeadersTables = styled.span`
  font-weight: 700;
  padding: 0 0.5rem;
`;

export const HeaderTotalDemand = styled.span`
  font-weight: 700;
`;

export const ValuesContainer = styled.span`
  padding: 0 0.5rem;
`;
