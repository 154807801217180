import { Flex, Row } from 'antd';
import styled from 'styled-components';

export const ContainerComponents = styled.div`
  background-color: ${({ theme }) => theme.colorsDesignSystem.tag_background};
`;

export const FormContent = styled.div`
  padding: 2rem 5.3rem;
`;

export const LastPriceContent = styled(Flex)`
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.lightCream};
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.softGold};
  border-radius: 0.375rem;
  margin-bottom: 1.5rem;

  svg {
    color: ${({ theme }) => theme.colorsDesignSystem.tagColorYellow};
    font-size: 0.875rem;
  }
`;

export const FormFooter = styled(Row)`
  justify-content: flex-end;
  border-top: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  padding: 1rem 1.25rem;
`;
