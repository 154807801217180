import { Tag } from './styles';
import { ITrackingNumbeTag } from './types';

export function TrackingTag({ status }: ITrackingNumbeTag) {
  return (
    <Tag status={status}>
      <div className="circle" />
      {status}
    </Tag>
  );
}
