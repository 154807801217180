import { useHistory } from 'react-router-dom';
import { RoutesPath } from '../../../routes/routesPath';

export default function useCardDistribution() {
  const history = useHistory();
  const handleNavigateToAwardSplit = (bidId: string, altGroup: string) => {
    history.push(RoutesPath.awardSplit, { bidId, altGroup });
  };

  const handleNavigateToAwardResume = (bidId: string, altGroup: string) => {
    history.push(RoutesPath.awardDetails, { bidId, altGroup });
  };

  return { handleNavigateToAwardSplit, handleNavigateToAwardResume };
}
