import { ReactNode } from 'react';
import { AreaChartOutlined, FundOutlined, GlobalOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { CardPriceProps } from './types';
import money from '../../../assets/money_icon.svg';
import theme from '../../../styles/theme';

export function useCardPrices() {
  const handleSelectIcon = (type: CardPriceProps['type']): ReactNode => {
    if (type === 'NET') return <img src={money} alt="money" />;
    if (type === 'BASELINE')
      return (
        <AreaChartOutlined style={{ color: theme.colorsDesignSystem.blue, fontSize: '1.25rem' }} />
      );
    if (type === 'FOB')
      return (
        <GlobalOutlined style={{ color: theme.colorsDesignSystem.blue, fontSize: '1.25rem' }} />
      );
    if (type === 'ESTIMATE')
      return <FundOutlined style={{ color: theme.colorsDesignSystem.blue, fontSize: '1.25rem' }} />;

    return null;
  };

  const handleSelectTitle = (type: CardPriceProps['type']): string => {
    if (type === 'BASELINE') return t('pages.awardSimulation.cardPricesTitles.baseline');
    if (type === 'ESTIMATE') return t('pages.awardSimulation.cardPricesTitles.estimate');
    if (type === 'NET') return t('pages.awardSimulation.cardPricesTitles.net');
    if (type === 'FOB') return t('pages.awardSimulation.cardPricesTitles.fob');

    return '';
  };

  return {
    handleSelectIcon,
    handleSelectTitle,
  };
}
