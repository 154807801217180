import { Flex } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  margin-bottom: 1.5rem;
`;

export const Padding = styled(Flex)`
  align-items: center;
  gap: 0.75rem;
  padding: 1.625rem 2rem;
`;

export const Header = styled(Flex)`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};

  svg {
    font-size: 1rem;
    color: ${({ theme }) => theme.colorsDesignSystem.iconGrey};
  }
`;

export const Content = styled(Flex)`
  padding: 2rem;
  flex-direction: column;

  .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }

  .ant-tabs-tab .ant-tabs-tab-active,
  .ant-tabs-tab {
    text-align: center;
    width: max-content;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    padding: 0;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    display: none;
  }
`;

export const Card = styled(Flex)`
  flex-direction: column;
  width: 24rem;
  padding: 1rem 1.5rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) =>
    theme.colorsDesignSystem.newTablesColors.colorBackgroundSubItens};
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  gap: 0.5rem;

  span {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
    line-height: 1.25rem;
  }
  .value {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    line-height: 1.75rem;
  }
`;

export const Title = styled.span`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.colorsDesignSystem.quartenaryText};
  margin-bottom: 2rem;
`;

export const Label = styled(Flex)<{ marginTop?: boolean }>`
  gap: 0.5rem;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.16rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  margin-bottom: 0.75rem;
  margin-top: ${({ marginTop }) => marginTop && '2rem'};
`;

export const Value = styled(Flex)`
  align-items: center;
  gap: 0.375rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};

  svg {
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
    font-size: 1rem;
    cursor: pointer;
  }
`;
