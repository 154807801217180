import { Divider, Flex } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Treemap } from 'recharts';
import { StyledLoading } from '../../../../components/Common/StyledLoading';
import theme from '../../../../styles/theme';
import { IParams } from '../types';
import * as S from './styles';
import { usePricesWin } from './usePricesWin';

export function PricesWin({ altGroup, category, supplier, idBid }: IParams) {
  const { t } = useTranslation();
  const {
    fetchGraphData,
    CustomizedContent,
    CustomTooltip,
    renderColorfulLegendText,
    onChangeOption,
    graphData,
    isLoading,
    selectedOption,
    toggleOptions,
  } = usePricesWin();

  useEffect(() => {
    fetchGraphData({ altGroup, category, supplier, idBid });
  }, [altGroup, category, supplier, selectedOption]);

  return (
    <S.Container>
      <S.TitleContainer>
        <Flex justify="space-between" align="center">
          <Flex align="center">
            <b>{t('pages.awards.managementBid.priceWin.title')}</b>
            <Divider type="vertical" />
            <span className="usd">USD</span>
          </Flex>
          <S.StyledRadio.Group
            options={toggleOptions}
            onChange={onChangeOption}
            value={selectedOption}
            optionType="button"
            buttonStyle="solid"
          />
        </Flex>
      </S.TitleContainer>
      <S.Content>
        {isLoading ? (
          <StyledLoading height={10} />
        ) : (
          <Flex vertical justify="center" align="center">
            <Treemap
              width={756}
              height={374}
              data={graphData}
              dataKey="total"
              stroke={theme.colorsDesignSystem.white}
              fill={theme.colorsDesignSystem.white}
              content={<CustomizedContent />}
            >
              <Tooltip content={<CustomTooltip />} />
            </Treemap>
            {graphData && (
              <S.LegendContainer>
                {graphData.map((item) => {
                  return (
                    <Flex key={item.altGroup} align="center" gap={8}>
                      <S.Legend color={item.children[0].fill} />
                      {item.altGroup}
                    </Flex>
                  );
                })}
              </S.LegendContainer>
            )}
          </Flex>
        )}
      </S.Content>
    </S.Container>
  );
}
