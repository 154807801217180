import { InfoCircleOutlined } from '@ant-design/icons';
import { DatePicker, Flex, Form, Input, InputNumber } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { LiaDollarSignSolid } from 'react-icons/lia';

import { useEffect } from 'react';
import { IStep1 } from './types';

import StyledButton from '../../../../components/Common/StyledButton';
import { StyledLoading } from '../../../../components/Common/StyledLoading';
import theme from '../../../../styles/theme';
import * as S from '../styles';
import { useRegisterBid } from './useRegisterBid';

const { RangePicker } = DatePicker;

export function RegisterBid({
  bidStatus,
  isLoadingSave,
  isLoadingData,
  onCancel,
  handleSubmit,
  originalBidId,
  setCurrentStep,
  setOriginalBidId,
  bidId,
  isFirstLoading,
  setIsFirstLoading,
}: IStep1) {
  const { getInformations, form } = useRegisterBid(bidId);
  const { t } = useTranslation();

  useEffect(() => {
    if (bidId) {
      getInformations(setCurrentStep, setOriginalBidId, isFirstLoading, setIsFirstLoading);
    }
  }, [bidId]);

  const validatePriceInterval = (value: string) => {
    if (!value || /^\d+\.\d{2}$/.test(value)) {
      return true;
    }
    return false;
  };

  const validateBidPeriod = (value: string) => {
    if (dayjs().format() > dayjs(value[0]).format()) {
      return false;
    }
    return true;
  };

  const handleValidateAttemptValue = (value: string) => {
    const regex = /^(0|[1-9]\d*)$/;
    return regex.test(value);
  };

  const validateValue = (value: string) => {
    if (!value || /^\d+(\.\d+)?$/.test(value)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <S.ContentForm>
        {isLoadingData ? (
          <StyledLoading height={30} />
        ) : (
          <S.StyledForm>
            <Form layout="vertical" requiredMark form={form}>
              <Form.Item
                data-cy="nameBid"
                label={t('pages.newRfq.steps.registerBid.fields.nameBID')}
                name="nameBid"
                rules={[
                  {
                    required: true,
                    message: `${t('pages.newRfq.steps.registerBid.fields.nameBID')} ${t(
                      `common.validate.required`
                    )}`,
                  },
                  {
                    min: 2,
                    message: t('pages.newRfq.steps.registerBid.fields.nameBIDMinCharacter'),
                  },
                  {
                    max: 70,
                    message: t('pages.newRfq.steps.registerBid.fields.nameBIDMaxCharacter'),
                  },
                ]}
              >
                <Input
                  placeholder={t('pages.newRfq.steps.registerBid.fields.placeholderNameBID')}
                  minLength={2}
                  maxLength={70}
                  disabled={!!originalBidId}
                />
              </Form.Item>
              <Form.Item
                data-cy="description"
                label={t('pages.newRfq.steps.registerBid.fields.description')}
                name="description"
              >
                <Input.TextArea
                  placeholder={t('pages.newRfq.steps.registerBid.fields.placeholderDescriptionBID')}
                  maxLength={400}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                />
              </Form.Item>

              <Form.Item noStyle>
                <Flex gap={24}>
                  <Form.Item
                    label={t('pages.newRfq.steps.registerBid.fields.BIDPeriod')}
                    name="period"
                    rules={[
                      {
                        required: true,
                        message: `${t('pages.newRfq.steps.registerBid.fields.BIDPeriod')} ${t(
                          `common.validate.required`
                        )}`,
                      },
                      {
                        validator: (_, value) =>
                          validateBidPeriod(value)
                            ? Promise.resolve()
                            : Promise.reject(
                                t('pages.newRfq.steps.registerBid.fields.startBIDPeriodError')
                              ),
                      },
                    ]}
                    shouldUpdate
                  >
                    <RangePicker
                      data-cy="rangePicker"
                      disabled={bidStatus === 'STARTED'}
                      showTime
                      placeholder={[
                        t('pages.newRfq.steps.registerBid.fields.startBIDPeriodPlaceholder'),
                        t('pages.newRfq.steps.registerBid.fields.endBIDPeriodPlaceholder'),
                      ]}
                    />
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      return (
                        <Form.Item
                          label={t('pages.newRfq.steps.registerBid.fields.priceInterval')}
                          name="priceInterval"
                          rules={[
                            {
                              validator: (_, value) =>
                                validatePriceInterval(value)
                                  ? Promise.resolve()
                                  : Promise.reject(
                                      t('pages.newRfq.steps.registerBid.fields.priceIntervalError')
                                    ),
                            },
                            {
                              validator: (_, value) =>
                                validateValue(value)
                                  ? Promise.resolve()
                                  : Promise.reject(t('toast.invalidField')),
                            },
                          ]}
                          tooltip={{
                            title: t('pages.newRfq.steps.registerBid.fields.priceIntervalTooltip'),
                            icon: <InfoCircleOutlined />,
                          }}
                        >
                          <Input
                            addonAfter={
                              <LiaDollarSignSolid
                                style={{ color: theme.colorsDesignSystem.primary }}
                              />
                            }
                            placeholder="0.00"
                            minLength={2}
                            maxLength={6}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                  <Form.Item
                    label={t('pages.newRfq.steps.registerBid.fields.attemptLimit')}
                    name="attemptLimit"
                    rules={[
                      {
                        validator: (_, value) =>
                          !value || handleValidateAttemptValue(value)
                            ? Promise.resolve()
                            : Promise.reject(t('common.validate.invalid')),
                      },
                    ]}
                    tooltip={{
                      title: t('pages.newRfq.steps.registerBid.fields.attemptLimitTooltip'),
                      icon: <InfoCircleOutlined />,
                    }}
                  >
                    <InputNumber
                      placeholder={t(
                        'pages.newRfq.steps.registerBid.fields.attemptLimitPlaceHolder'
                      )}
                      style={{ width: 151 }}
                      defaultValue="0"
                      maxLength={2}
                    />
                  </Form.Item>
                </Flex>
              </Form.Item>
            </Form>
          </S.StyledForm>
        )}
      </S.ContentForm>
      <S.StepFooter>
        <StyledButton variant="slim" onClick={onCancel}>
          {t('common.cancel')}
        </StyledButton>

        <StyledButton
          variant="primary"
          loading={isLoadingSave}
          onClick={() => {
            form.validateFields().then(async () => {
              handleSubmit(form.getFieldsValue(true));
            });
          }}
          htmlType="submit"
          disabled={isLoadingSave || isLoadingData}
        >
          {t('common.next')}
        </StyledButton>
      </S.StepFooter>
    </>
  );
}
