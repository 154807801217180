import { Flex } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  .ant-steps-item-container {
    border-radius: 0.5rem;
    width: 100%;
  }

  .ant-steps-item-content {
    width: 100%;
  }

  .ant-steps-item-title {
    width: 100%;
    padding: 0;
  }

  .ant-steps-item-icon {
    display: none;
  }

  .ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    padding: 1rem;
  }

  .pickup {
    .ant-steps-item-title {
      border-radius: 0.5rem;
      border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tagColorOrange};
      background-color: white;
      z-index: 2;
    }
    .ant-steps-item-container > .ant-steps-item-tail::after {
      position: absolute;
      top: 20px;
      inset-inline-start: 40px;
      width: 1px;
      height: 100%;
      background-color: ${({ theme }) => theme.colorsDesignSystem.tagColorOrange};
    }
  }

  .transit,
  .out-delivery {
    .ant-steps-item-title {
      border-radius: 0.5rem;
      border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tagColorBlue};
    }
    .ant-steps-item-container > .ant-steps-item-tail::after {
      position: absolute;
      top: 20px;
      inset-inline-start: 40px;
      width: 1px;
      height: 100%;
      background-color: ${({ theme }) => theme.colorsDesignSystem.tagColorBlue};
    }
  }

  .temporary-hold {
    .ant-steps-item-title {
      border-radius: 0.5rem;
      border: 1px solid ${({ theme }) => theme.colorsDesignSystem.yellow};

      svg {
        color: ${({ theme }) => theme.colorsDesignSystem.yellow};
        font-size: 1.5rem;
      }
    }
    .ant-steps-item-container > .ant-steps-item-tail::after {
      position: absolute;
      top: 20px;
      inset-inline-start: 40px;
      width: 1px;
      height: 100%;
      background-color: ${({ theme }) => theme.colorsDesignSystem.yellow};
    }
  }

  .delivered {
    .ant-steps-item-title {
      border-radius: 0.5rem;
      border: 1px solid ${({ theme }) => theme.colorsDesignSystem.maximumGreen};

      svg {
        font-size: 1.5rem;
        color: ${({ theme }) => theme.colorsDesignSystem.avocato};
      }
    }

    .ant-steps-item-container > .ant-steps-item-tail::after {
      position: absolute;
      top: 20px;
      inset-inline-start: 40px;
      width: 1px;
      height: 100%;
      background-color: ${({ theme }) =>
        theme.colorsDesignSystem.qualificationTagColors.brightGreen};
    }
  }
`;

export const Content = styled(Flex)`
  width: 100%;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border-radius: 0.5rem;
  gap: 2rem;
  align-items: center;

  img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const TitleContent = styled(Flex)`
  width: 8.5rem;
  gap: 0.5rem;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.1155rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const DescriptionContent = styled(Flex)`
  flex-direction: column;
  text-align: right;
  flex: 1;
  align-items: center;
`;

export const Description = styled.span<{ size?: 'normal' | 'small' }>`
  font-size: ${({ size }) => (size === 'small' ? '0.625rem' : '0.75rem')};
  font-weight: 400;
  line-height: 1.1155rem;
  color: ${({ theme }) => theme.colorsDesignSystem.localColor};
  text-align: left;
  width: 100%;

  .local {
    width: 0.875rem;
    height: 0.875rem;
  }
`;
