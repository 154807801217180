import { AcerLinksHeader } from '@fitec/acer-header';
import { Badge, Divider, Drawer, Menu, Row, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiMenu } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { useNotifications } from '../../../context/notification';
import { debounce } from '../../../helpers/utils';
import { RoutesPath } from '../../../routes/routesPath';
import {
  getAllowedRoutes,
  getIdToken,
  getPermissions,
  getRefreshToken,
  getUserId,
  handleVerifyUserIsSupplier,
  logout,
} from '../../../services/auth';
import { changeLanguage } from '../../../translations/i18n';
import { LoginHeader } from '../LoginHeader';
import { NotificationsMenu } from '../NotificationsMenu';
import * as S from './styles';

export function NavBar() {
  const permissions = getPermissions();
  const { t } = useTranslation();
  const history = useHistory();

  const userId = getUserId();
  const refreshToken = getRefreshToken();
  const idToken = getIdToken();

  const {
    isOpen,
    handleOpen,
    onClose: onCloseNotifications,
    notifications,
    closeWebSocketConnection,
  } = useNotifications();
  const [current, setcurrent] = useState('');
  const [language, setLanguage] = useState(true);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setcurrent(history.location.pathname);
    setLanguage(!localStorage?.getItem('language')?.includes('pt_br'));
  }, []);

  const handleOpenMenu = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const onChangeLanguage = () => {
    setLanguage(!language);
    changeLanguage(localStorage.getItem('language') === 'en' ? 'pt_br' : 'en');
  };

  const handleClick = (e) => {
    setcurrent(e.key);
    history.push(e.key);
    onClose();
  };

  const navConfig = [
    //finance
    {
      //finance
      path: 'finance',
      title: t('components.navigation.Finance'),
      children: [
        { path: RoutesPath.average, title: t('pages.average.title') },
        { path: RoutesPath.financeDashboard, title: t('components.navigation.FinanceDash') },
        {
          path: RoutesPath.procurementDashboard,
          title: t('components.navigation.ProcurementDash'),
        },
        { path: RoutesPath.quotes, title: t('pages.quotes.title') },
      ],
    },
    //PPB
    {
      path: 'PPB',
      title: 'PPB',
      children: [
        { path: RoutesPath.simulation, title: t('pages.simulation.title') },
        { path: RoutesPath.scenarios, title: t('pages.scenarios.title') },
        { path: RoutesPath.forecasts, title: t('components.navigation.DemandScenarios') },
        { path: RoutesPath.prices, title: t('components.navigation.PricesScenarios') },
      ],
    },
    //cost -> falta adicionar packing
    {
      path: 'cost',
      title: t('components.navigation.Cost'),
      children: [
        { path: RoutesPath.componentsPrices, title: t('pages.componentsPrices.Title') },
        { path: RoutesPath.pricesTaxes, title: t('pages.pricesTaxes.title') },
      ],
    },
    //agrupador
    {
      path: 'Agrupador',
      title: t('components.navigation.MaterialData'),
      children: [
        { path: RoutesPath.avlc, title: 'AVLC' },
        { path: RoutesPath.boms, title: 'BOM' },
        { path: RoutesPath.components, title: t('pages.components.title') },
        { path: RoutesPath.sku, title: 'SKU' },
        { path: RoutesPath.inventory, title: 'Inventory' },
      ],
    },
    //strategies
    {
      path: 'Strategies',
      title: t('components.navigation.Strategies'),
      children: [
        /*{
          path: RoutesPath.dashboardSpecifications,
          title: t('pages.dashboard.titleNavShareProportion'),
        },*/
        { path: RoutesPath.spot, title: t('pages.spot.title') },
        { path: RoutesPath.shareProportion, title: t('pages.shareProportion.title') },
      ],
    },
    //planning
    {
      path: 'Planning',
      title: t('components.navigation.Planning'),
      children: [{ path: RoutesPath.newForecast, title: t('components.navigation.Demand') }],
    },
    //RFG
    {
      path: 'RFQ',
      title: t('components.navigation.Rfq'),
      children: [
        { path: RoutesPath.suppliers, title: t('pages.suppliers.title') },
        { path: RoutesPath.rfq, title: t('pages.rfq.titleMenu') },
      ],
    },
    //dashboard
    {
      path: '/dashboard',
      title: t('components.navigation.Dashboards'),
      children: [
        { path: RoutesPath.financeDashboard, title: t('components.navigation.FinanceDash') },
        {
          path: RoutesPath.procurementDashboard,
          title: t('components.navigation.ProcurementDash'),
        },
        {
          path: RoutesPath.dashboardSpecifications,
          title: t('pages.dashboard.titleNavShareProportion'),
        },
        { path: RoutesPath.dashboardSKU, title: 'SKU' },
      ],
    },
    {
      path: 'subreports',
      title: t('common.reports'),
      children: [
        { path: RoutesPath.reportsScenarios, title: t('pages.reports.scenarios.titleMenu') },
      ],
    },
    {
      path: '/qualification',
      title: t('components.navigation.Qualification'),
    },
    {
      path: 'subsettings',
      title: t('common.settings'),
      children: [
        { path: RoutesPath.alerts, title: t('pages.alert.menuTitle') },
        { path: RoutesPath.componentsgroup, title: t('pages.componentsGroup.title') },
        { path: RoutesPath.fileerrors, title: t('pages.fileerrors.title') },
        { path: RoutesPath.logusers, title: t('pages.logusers.title') },
        { path: RoutesPath.ppb, title: t('components.navigation.ppbRules') },
        { path: RoutesPath.taxes, title: t('components.navigation.SettingTaxes') },
        { path: RoutesPath.users, title: t('pages.user.title') },
      ],
    },
    { path: RoutesPath.uploads, title: t('pages.finance.uploadFiles.menuTitle') },
  ];

  const userAllowedRoutes = getAllowedRoutes();

  function filterNavLinks(config, allowedRoutes) {
    // if user is admin, return all routes
    if (permissions === 'admin') {
      return config;
    }

    // if user is not admin, filter links in navConfig
    return config.filter((route) => {
      if (!route.children) {
        // if route has no children, check if it is in allowedRoutes
        return allowedRoutes.some((avail) => avail.path === route.path);
      }

      // if route has children, filter children and check if there are any left
      route.children = filterNavLinks(route.children, allowedRoutes);
      return route.children.length > 0;
    });
  }

  const navLinks = filterNavLinks(navConfig, userAllowedRoutes);

  const items = navLinks.map((navLink) => {
    return {
      key: navLink.path,
      label: navLink.title,
      'data-cy': navLink.path,
      children: navLink.children?.map((child) => {
        return {
          key: child.path,
          'data-cy': child.path,
          label: child.title,
        };
      }),
    };
  });

  items.push(
    {
      key: 'divider',
      disabled: true,
      label: <Divider />,
    },
    {
      key: 'language',
      disabled: true,
      label: (
        <Row align="middle">
          <S.Label>{t('common.language')} </S.Label>
          <S.Switch
            checked={language}
            checkedChildren="ENG"
            unCheckedChildren="PT-BR"
            onChange={debounce(() => {
              onChangeLanguage();
            }, 300)}
            style={{ margin: '1rem' }}
          />
        </Row>
      ),
    }
  );

  return (
    <>
      <AcerLinksHeader idToken={idToken} redirectInfo={{ userId, refreshToken }} />
      <LoginHeader>
        <div style={{ display: 'flex', gap: 16 }}>
          <S.MenuButtonContainer onClick={handleOpen}>
            <Tooltip title={t('notifications.title')}>
              <Badge
                count={notifications?.filter((n) => !n.visualized)?.length}
                color="#FF9A02"
                overflowCount={99}
                size="small"
              >
                <S.BellIconContainer>
                  <S.BellOutlinedIcon />
                </S.BellIconContainer>
              </Badge>
            </Tooltip>
          </S.MenuButtonContainer>
          <S.MenuButtonContainer onClick={handleOpenMenu} data-cy="menuButton">
            <span className="menu-btn">Menu</span>
            <FiMenu fontSize={24} color="#fff" />
          </S.MenuButtonContainer>
        </div>
      </LoginHeader>

      <Drawer
        title="Menu"
        placement="right"
        onClose={onClose}
        open={visible}
        width={256}
        className="drawer-menu"
        styles={{
          body: { padding: 0 },
        }}
        footer={
          <S.LogoutContainer
            onClick={() => {
              closeWebSocketConnection();
              logout();
            }}
          >
            <S.LogoutLink> {t('common.logout')}</S.LogoutLink>
          </S.LogoutContainer>
        }
      >
        {!handleVerifyUserIsSupplier() && (
          <S.MenuContainer>
            <Menu
              onClick={handleClick}
              style={{ width: '100%' }}
              defaultSelectedKeys={[current]}
              items={items}
            />
          </S.MenuContainer>
        )}
      </Drawer>
      <NotificationsMenu open={isOpen} onClose={onCloseNotifications} />
    </>
  );
}
