import { Steps } from 'antd';
import * as S from './styles';
import { ISampleStatus } from './types';
import { useSampleStatus } from './useSampleStatus';

export function SampleStatus({ dhlData, fedexData }: ISampleStatus) {
  const { handleStepsFedex, handleStepsDHL } = useSampleStatus();

  return (
    <S.Container>
      <Steps
        items={dhlData ? handleStepsDHL(dhlData) : fedexData ? handleStepsFedex(fedexData) : []}
        direction="vertical"
        labelPlacement="vertical"
      />
    </S.Container>
  );
}
