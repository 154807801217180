import { FileAddOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import SimpleTable from '../../../../components/Tables/SimpleTable';
import * as S from './styles';
import { IDatasheetComponent } from './types';
import { useDatasheet } from './useDatasheet';

export function Datasheet({ qualificationId }: IDatasheetComponent) {
  const { t } = useTranslation();
  const { columns, handleChangeTable, datasheetData, isLoading } = useDatasheet();
  return (
    <S.Container>
      <S.Header>
        <S.Padding>
          <FileAddOutlined />
          <span>{t('pages.viewQualification.datasheet.title')}</span>
        </S.Padding>
      </S.Header>
      <S.Content>
        {datasheetData.length === 0 ? (
          <Empty
            description={t('pages.viewQualification.datasheet.empty')}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : (
          <SimpleTable
            columns={columns}
            dataSource={datasheetData}
            rowKey="fileName"
            pagination={false}
            onChange={handleChangeTable}
            isLoading={isLoading}
          />
        )}
      </S.Content>
    </S.Container>
  );
}
