import { Flex } from 'antd';
import { MessageOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { CardAwardProps } from './types';
import * as S from './styles';
import { useCardAwards } from './useCardAwards';
import theme from '../../../styles/theme';

export function CardAwards({ project, remark, cards }: CardAwardProps) {
  const { handleRenderCardsInside } = useCardAwards();

  return (
    <S.ContainerExpandableContent>
      <S.ContainerAwardCard>
        <S.Labels className="title">{project}</S.Labels>
        {handleRenderCardsInside(cards)}
        <S.ContainerFooterProjectCard>
          <S.LineDashed />
          <Flex vertical gap={16}>
            <Flex align="center" gap={8}>
              <MessageOutlined style={{ color: theme.colorsDesignSystem.primary }} />
              <span>{t('pages.awardDetails.labels.remark')}</span>
            </Flex>
            <span className="destak">{remark || '-'}</span>
          </Flex>
        </S.ContainerFooterProjectCard>
      </S.ContainerAwardCard>
    </S.ContainerExpandableContent>
  );
}
