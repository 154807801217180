/* eslint-disable no-undefined */
import { Divider, Empty, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { UploadedFileCard } from '../UploadedFileCard';
import * as S from './styles';
import { IQuotationFilesModal } from './types';

export function QuotationFilesModal({
  uploadedFiles,
  removeFile,
  fileIdDelete,
}: IQuotationFilesModal) {
  const { t } = useTranslation();
  const hasFiles = uploadedFiles && uploadedFiles?.length > 0;

  return (
    <S.Container>
      <Divider orientation="left" orientationMargin={0}>
        <S.DividerText>{t('pages.bidSupplierRFQ.quotation.modal.uploadedFiles')}</S.DividerText>
      </Divider>
      <Flex vertical gap={16} style={{ maxHeight: '24rem', overflowY: 'auto' }}>
        {!hasFiles && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {uploadedFiles &&
          uploadedFiles.length > 0 &&
          uploadedFiles?.map((file) => {
            return (
              <UploadedFileCard
                key={file?.uploadedFileId}
                fileName={file?.fileName || '-'}
                status={file?.response || 'done'}
                fileId={file?.uploadedFileId}
                fileIdDelete={fileIdDelete}
                onRemoveFile={() => {
                  file.uploadedFileId && removeFile(file.uploadedFileId, file.response || 'done');
                }}
                {...file}
              />
            );
          })}
      </Flex>
    </S.Container>
  );
}
