import { ReactNode, useState } from 'react';
import { t } from 'i18next';
import { useHistory } from 'react-router-dom';
import * as S from './styles';
import { api } from '../../services/api';
import { services } from '../../services/services';
import { getURI, oneAlert } from '../../helpers/utils';
import { AwardDetailsProps } from './types';
import { CardSuppliers } from '../../components/Common/CardBidSuplliers';
import { handleFormatValueFields } from '../../helpers/nUtils';
import { CardAwards } from './CardAwards';
import { RoutesPath } from '../../routes/routesPath';

export function useAwardDetails() {
  const [loading, setLoading] = useState(false);
  const [awardDetails, setAwardDetails] = useState<AwardDetailsProps>();
  const [isOpenCardDetails, setIsOpenDetails] = useState(true);
  const [isOpenCardAward, setIsOpenAward] = useState(true);
  const history = useHistory();

  const handleRenderSpecs = () => {
    return awardDetails?.specs.map((spec, index) => {
      return (
        <S.Labels className="normal" key={spec}>
          {index + 1 === awardDetails.specs.length ? spec : `${spec} |`}
        </S.Labels>
      );
    });
  };

  const handleFetchDetailsAward = async (bidId: string, altGroup: string) => {
    try {
      setLoading(true);
      const { data, status } = await api.get(
        getURI(`${services.rfq}/award-simulation/${bidId}/split/review`, { altGroup })
      );
      if (status === 200) {
        setAwardDetails(data);
      }
    } catch (error: any) {
      console.log(error);
      oneAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setLoading(false);
    }
  };

  const handleRenderCardsSuppliers = (): ReactNode => {
    try {
      return awardDetails?.groupDetail.suppliers.map((supplier) => {
        return (
          <CardSuppliers
            key={supplier.supplier}
            demand={String(supplier.demand)}
            supplier={supplier.supplier || '-'}
            impact={String(supplier.impactSavingPercentage)}
            saving={String(supplier.impactSaving)}
            totalWin={String(supplier.totalWin)}
          />
        );
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleCalculateProjectsConcludedPercent = (total: number, concluded: number): number => {
    try {
      return (concluded / total) * 100;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const handleRenderAwardCards = (): ReactNode => {
    try {
      return awardDetails?.cards.map((cardItem) => {
        return (
          <CardAwards
            key={cardItem.project}
            project={cardItem.project}
            remark={cardItem.remark}
            cards={cardItem.ranking}
          />
        );
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const handleFinish = (bidId: string) => {
    history.push(`/awards/${bidId}`);
  };

  const handleNavigateToAwardSplit = (bidId: string, altGroup: string) => {
    history.push(RoutesPath.awardSplit, { bidId, altGroup });
  };

  const handleVerifyIfAllProjectsIsDone = (): boolean => {
    return awardDetails?.totalProjects === awardDetails?.projectsConcluded;
  };

  return {
    handleFetchDetailsAward,
    handleRenderCardsSuppliers,
    setIsOpenAward,
    setIsOpenDetails,
    handleRenderAwardCards,
    handleCalculateProjectsConcludedPercent,
    handleRenderSpecs,
    handleNavigateToAwardSplit,
    handleFinish,
    handleVerifyIfAllProjectsIsDone,
    isOpenCardAward,
    isOpenCardDetails,
    awardDetails,
    loading,
  };
}
