import { Upload } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  overflow-y: hidden;
`;

export const DividerText = styled.span`
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  font-size: 1rem;
  font-weight: 700;
`;

export const StyledUpload = styled(Upload)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  height: 10.5rem;
  border: 1px dashed ${({ theme }) => theme.colorsDesignSystem.primary};
  background-color: ${({ theme }) =>
    theme.colorsDesignSystem.newTablesColors.colorBackgroundSubItens};
  border-radius: 0.75rem;
  .uploadIcon {
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
    font-size: 2.5rem;
  }
`;

export const DradDropTitle = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.black85};
  margin-top: 1rem;
`;

export const DradDropDescription = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.grayText};
`;

export const MessageError = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.red};
  strong {
    font-weight: 700;
  }
`;
