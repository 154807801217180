import { useEffect } from 'react';
import { StyledLoading } from '../../../components/Common/StyledLoading';
import SimpleTable from '../../../components/Tables/SimpleTable';
import * as S from './styles';
import { useQuotationFiles } from './useQuotationFiles';

export function QuotationFilesPages() {
  const { columns, quotationFilesData, loading, fetchQuotationFilesData, handleChangeTable } =
    useQuotationFiles();

  useEffect(() => {
    fetchQuotationFilesData();
  }, []);
  return (
    <S.Container>
      {loading ? (
        <StyledLoading height={35} />
      ) : (
        <SimpleTable
          pagination={false}
          columns={columns}
          dataSource={quotationFilesData}
          rowKey="fileName"
          onChange={handleChangeTable}
        />
      )}
    </S.Container>
  );
}
