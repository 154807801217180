import { Steps } from 'antd';
import { useEffect } from 'react';
import * as S from './styles';
import { useQualificationStatus } from './useQualificationStatus';

export function QualificationStatus() {
  const { steps, formatSteps } = useQualificationStatus();

  useEffect(() => {
    formatSteps();
  }, []);

  return (
    <S.StepsContent>
      <Steps items={steps} />
    </S.StepsContent>
  );
}
