import { Divider as AntDivider, Flex } from 'antd';
import styled from 'styled-components';

export const Container = styled(Flex)`
  overflow-y: auto;
  padding: 1.5rem;
`;

export const TrackingNumberInformation = styled(Flex)`
  flex-direction: column;
  width: 22rem;
  height: max-content;
  gap: 1.5rem;
  padding: 1rem 1.5rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  border-radius: 0.75rem;

  .arrow {
    font-size: 1rem;
    color: ${({ theme }) => theme.colorsDesignSystem.grayBorderTooltip};
  }
`;

export const Icon = styled(Flex)`
  width: 2.5rem;
  height: 2.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colorsDesignSystem.neutralGray};

  svg {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
  }
`;

export const Label = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const Value = styled.span<{ size?: 'large' | 'normal' }>`
  font-size: ${({ size }) => (size === 'large' ? '1rem' : '0.75rem')};
  font-weight: 700;
  line-height: ${({ size }) => (size === 'large' ? '1.375rem' : '0.875rem')};
  color: ${({ theme }) => theme.colorsDesignSystem.text};

  i {
    font-weight: 400;
  }
`;

export const Divider = styled(AntDivider)`
  margin: 0px;
`;

export const TrackingNumberStatus = styled(Flex)`
  width: 28rem;
  padding: 1.5rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  border-radius: 0.75rem;
  flex: 1;
`;

export const StatusTitle = styled.span`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.colorsDesignSystem.quartenaryText};
`;
