import { useTranslation } from 'react-i18next';
import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import StyledTabs from '../../components/Common/StyledTabs';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { AwardListPage } from './AwardList';
import { BidAllocationPage } from './BidAllocation';
import { ManagementBID } from './ManagementBid';
import { QuotationFilesPages } from './QuotationFiles';
import * as S from './styles';
import { useAwardSummary } from './useAwardSummary';
import { AwardSimulation } from '../AwardSimulation';

export function AwardsPage() {
  useDocumentTitle('Awards');
  const { t } = useTranslation();
  const { setTabs, tabs, bidData } = useAwardSummary();

  return (
    <S.Container>
      <StyledHeader title="Awards" subtitle={bidData?.name} />
      <StyledTabs tabs={tabs} setSelectedTab={setTabs} />
      {tabs[0].selected && <AwardSimulation />}
      {tabs[1].selected && <BidAllocationPage />}
      {tabs[2].selected && <AwardListPage />}
      {tabs[3].selected && <ManagementBID />}
      {tabs[4].selected && <QuotationFilesPages />}
    </S.Container>
  );
}
