import { Flex } from 'antd';
import styled from 'styled-components';

export const FiltersContainer = styled(Flex)`
  gap: 1rem;
  align-items: flex-end;
  margin-bottom: 1rem;
  margin-top: 2.5rem;
`;

export const TagsContainer = styled(Flex)`
  gap: 0.375rem;
  margin-bottom: 1rem;
`;
