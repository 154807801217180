/* eslint-disable no-undefined */
import { UploadOutlined } from '@ant-design/icons';
import { Alert, Divider, Empty, Flex, Progress, UploadProps } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadedFileCard } from '../UploadedFileCard';
import * as S from './styles';
import { IQuotationUploadModal } from './types';

export function QuotationUploadModal({
  uploadedFiles,
  handleChange,
  removeFile,
  fileList,
  uploadProgress,
  loading,
  fileIdDelete,
}: IQuotationUploadModal) {
  const { t } = useTranslation();
  const hasFiles =
    (uploadedFiles && uploadedFiles?.length > 0) || (fileList && fileList?.length > 0);

  const props: UploadProps = {
    accept: '.pdf',
    name: 'file',
    multiple: true,
    hasControlInside: undefined,
    fileList,
    beforeUpload: () => {
      return false;
    },
  };

  const handleRenderErrorMessage = (error: string): ReactNode => {
    if (error === 'formatError') {
      return (
        <Alert
          type="error"
          message={
            <S.MessageError>
              <strong>{t('pages.bidSupplierRFQ.quotation.modal.formatErrorTitle')}</strong>
              {t('pages.bidSupplierRFQ.quotation.modal.formatErrorDescription')}
            </S.MessageError>
          }
        />
      );
    }
    if (error === 'sizeError') {
      return (
        <Alert
          type="error"
          message={
            <S.MessageError>
              <strong>{t('pages.bidSupplierRFQ.quotation.modal.sizeErrorTitle')}</strong>
              {t('pages.bidSupplierRFQ.quotation.modal.sizeErrorDescription')}
            </S.MessageError>
          }
        />
      );
    }
    if (error === 'processingError') {
      return (
        <Alert
          type="error"
          message={
            <S.MessageError>
              <strong>{t('pages.bidSupplierRFQ.quotation.modal.processingErrorTitle')}</strong>
              {t('pages.bidSupplierRFQ.quotation.modal.processingErrorDescription')}
            </S.MessageError>
          }
        />
      );
    }
    if (error === 'formatErrorSizeError') {
      return (
        <Flex gap={8}>
          {handleRenderErrorMessage('formatError')}
          {handleRenderErrorMessage('sizeError')}
        </Flex>
      );
    }
  };

  return (
    <S.Container>
      <Divider orientation="left" orientationMargin={0}>
        <S.DividerText>
          {t('pages.bidSupplierRFQ.quotation.modal.quotationFileSubmission')}
        </S.DividerText>
      </Divider>
      <S.StyledUpload {...props} disabled={loading} onChange={handleChange}>
        <Flex align="center" justify="center" vertical>
          <UploadOutlined className="uploadIcon" />
          <S.DradDropTitle>
            {t('pages.bidSupplierRFQ.quotation.modal.dragAndDropTitle')}
          </S.DradDropTitle>
          <S.DradDropDescription>
            {t('pages.bidSupplierRFQ.quotation.modal.dragAndDropDescription')}
          </S.DradDropDescription>
          <S.DradDropDescription>
            {t('pages.bidSupplierRFQ.quotation.modal.dragAndGropLimit')}
          </S.DradDropDescription>
        </Flex>
      </S.StyledUpload>
      <Divider orientation="left" orientationMargin={0}>
        <S.DividerText>{t('pages.bidSupplierRFQ.quotation.modal.uploadedFiles')}</S.DividerText>
      </Divider>
      <Flex vertical gap={16} style={{ maxHeight: '24rem', overflowY: 'auto' }}>
        {!hasFiles && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {fileList &&
          fileList.length > 0 &&
          fileList?.map((file) => {
            return (
              <>
                <UploadedFileCard
                  key={file?.uid}
                  fileName={file?.name || '-'}
                  status={file?.response !== 'uploading' ? 'error' : 'uploading'}
                  onRemoveFile={() => removeFile(file.uid, file.response)}
                  fileId={file?.uid}
                  fileIdDelete={fileIdDelete}
                />
                {file?.uid && uploadProgress[file?.uid] && (
                  <Progress percent={Number(uploadProgress[file?.uid])} />
                )}
                {handleRenderErrorMessage(file.response)}
              </>
            );
          })}
        {uploadedFiles &&
          uploadedFiles.length > 0 &&
          uploadedFiles?.map((file) => {
            return (
              <UploadedFileCard
                key={file?.uploadedFileId}
                fileName={file?.fileName || '-'}
                status={file?.response || 'done'}
                fileId={file.uploadedFileId}
                onRemoveFile={() => {
                  file.uploadedFileId && removeFile(file?.uploadedFileId, file?.response || 'done');
                }}
                fileIdDelete={fileIdDelete}
                {...file}
              />
            );
          })}
      </Flex>
    </S.Container>
  );
}
