import styled from 'styled-components';
import { QualificationTagStatus } from '../../../helpers/enums';
import styledTheme from '../../../styles/theme';

const BackgroundColor = {
  'Sent Supplier': styledTheme.colorsDesignSystem.qualificationTagColors.lightYellow,
  'Sent ODM': styledTheme.colorsDesignSystem.qualificationTagColors.paleGreen,
  Cancel: styledTheme.colorsDesignSystem.qualificationTagColors.lightRed,
  Concluded: styledTheme.colorsDesignSystem.qualificationTagColors.lightGray,
};

const BorderColor = {
  'Sent Supplier': styledTheme.colorsDesignSystem.qualificationTagColors.softOrange,
  'Sent ODM': styledTheme.colorsDesignSystem.qualificationTagColors.mintGreen,
  Cancel: styledTheme.colorsDesignSystem.qualificationTagColors.softRed,
  Concluded: styledTheme.colorsDesignSystem.qualificationTagColors.mediumGray,
};

const FontColor = {
  'Sent Supplier': styledTheme.colorsDesignSystem.qualificationTagColors.goldenYellow,
  'Sent ODM': styledTheme.colorsDesignSystem.qualificationTagColors.brightGreen,
  Cancel: styledTheme.colorsDesignSystem.qualificationTagColors.vividRed,
  Concluded: styledTheme.colorsDesignSystem.qualificationTagColors.darkGray,
};

export const Container = styled.div<{ status: keyof typeof QualificationTagStatus }>`
  width: max-content;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  color: ${({ status }) => FontColor[status]};
  background-color: ${({ status }) => BackgroundColor[status]};
  border: 1px solid ${({ status }) => BorderColor[status]};
`;
