import { CheckCircleOutlined, SecurityScanOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import CircularArrowSvg from '../../../../assets/circular-arrow.svg';
import IconLocalSvg from '../../../../assets/icon-local.svg';
import WatchSvg from '../../../../assets/watch.svg';
import { IDHLData, IFedexData } from '../types';
import * as S from './styles';
import { IStepSatus } from './types';

export function useSampleStatus() {
  const { t } = useTranslation();

  const handleTitle = (
    status: 'delivered' | 'pickup' | 'transit' | 'out-delivery' | 'temporary-hold'
  ) => {
    switch (status) {
      case 'pickup':
        return (
          <S.TitleContent>
            <img src={WatchSvg} alt="" />
            <S.Title>
              {t(
                'components.modals.sampleTrackingNumber.qualificationTrackingStatus.pickupSchedule'
              )}
            </S.Title>
          </S.TitleContent>
        );

      case 'transit':
        return (
          <S.TitleContent>
            <img src={CircularArrowSvg} alt="" />
            <S.Title>
              {t('components.modals.sampleTrackingNumber.qualificationTrackingStatus.inTransit')}
            </S.Title>
          </S.TitleContent>
        );

      case 'out-delivery':
        return (
          <S.TitleContent>
            <img src={CircularArrowSvg} alt="" />
            <S.Title>
              {t(
                'components.modals.sampleTrackingNumber.qualificationTrackingStatus.outForDelivery'
              )}
            </S.Title>
          </S.TitleContent>
        );

      case 'delivered':
        return (
          <S.TitleContent>
            <CheckCircleOutlined />
            <S.Title>
              {t(
                'components.modals.sampleTrackingNumber.qualificationTrackingStatus.requestFinalized'
              )}
            </S.Title>
          </S.TitleContent>
        );
      default:
        return (
          <S.TitleContent>
            <SecurityScanOutlined />
            <S.Title>
              {t(
                'components.modals.sampleTrackingNumber.qualificationTrackingStatus.temporaryHold'
              )}
            </S.Title>
          </S.TitleContent>
        );
    }
  };

  const handleClassForDHL = (description: string, status: string) => {
    if (description.includes('picked up') && status === 'transit') return 'pickup';

    if (description.includes('for delivery') && status === 'transit') return 'out-delivery';

    if (status === 'delivered') return 'delivered';

    if (status === 'transit') return 'transit';

    return 'temporary-hold';
  };

  const handleStepsDHL = (dhlData: IDHLData) => {
    const items: IStepSatus[] = [];
    dhlData.shipments[0].events.reverse().forEach((event: any) => {
      const status = handleClassForDHL(event.description, event.statusCode);
      const titleContent = handleTitle(status);

      items.push({
        title: (
          <S.Content>
            {titleContent}
            <S.DescriptionContent>
              <S.Description>{dayjs(event.timestamp).format('MM/DD/YYYY hh:mma')}</S.Description>
              <S.Description>
                <Flex gap={4} align="center">
                  <img src={IconLocalSvg} alt="" className="local" />
                  {event.location.address.addressLocality} - {event.location.address.countryCode}
                </Flex>
              </S.Description>
              <S.Description size="small">{event.description}</S.Description>
            </S.DescriptionContent>
          </S.Content>
        ),
        key: status,
        className: status,
        status: 'finish',
      });
    });

    return items;
  };

  const handleStepsFedex = (fedexData: IFedexData) => {
    const items: IStepSatus[] = [];

    if (fedexData.shipDate) {
      const status = 'pickup';
      items.push({
        title: (
          <S.Content>
            {handleTitle(status)}
            <S.DescriptionContent>
              <S.Description>{dayjs(fedexData.shipDate).format('MM/DD/YYYY hh:mma')}</S.Description>
              <S.Description>{fedexData.from}</S.Description>
            </S.DescriptionContent>
          </S.Content>
        ),
        key: status,
        className: status,
        status: 'finish',
      });
    }

    if (fedexData.deliveryStatus === 'Delivered') {
      const status = 'delivered';
      items.push({
        title: (
          <S.Content>
            {handleTitle(status)}
            <S.DescriptionContent>
              <S.Description>
                {dayjs(fedexData.delivered).format('MM/DD/YYYY hh:mma')}
              </S.Description>
              <S.Description>{fedexData.deliveryStatus}</S.Description>
            </S.DescriptionContent>
          </S.Content>
        ),
        key: status,
        className: status,
        status: 'finish',
      });
    } else if (fedexData.delivered.toLowerCase().includes('transit')) {
      const status = 'transit';
      items.push({
        title: (
          <S.Content>
            {handleTitle(status)}
            <S.DescriptionContent>
              <S.Description>
                {dayjs(fedexData.delivered).format('MM/DD/YYYY hh:mma')}
              </S.Description>
              <S.Description>{fedexData.deliveryStatus}</S.Description>
            </S.DescriptionContent>
          </S.Content>
        ),
        key: status,
        className: status,
        status: 'finish',
      });
    } else {
      const status = 'temporary-hold';
      items.push({
        title: (
          <S.Content>
            {handleTitle(status)}
            <S.DescriptionContent>
              <S.Description>
                {dayjs(fedexData.delivered).format('MM/DD/YYYY hh:mma')}
              </S.Description>
              <S.Description>{fedexData.deliveryStatus}</S.Description>
            </S.DescriptionContent>
          </S.Content>
        ),
        key: status,
        className: status,
        status: 'finish',
      });
    }

    return items;
  };

  return {
    handleClassForDHL,
    handleStepsDHL,
    handleStepsFedex,
    handleTitle,
  };
}
