import {
  FolderViewOutlined,
  HourglassOutlined,
  SendOutlined,
  SnippetsOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BoxSvg from '../../../assets/box.svg';
import * as S from './styles';
import { ISteps } from './types';

export function useQualificationStatus() {
  const { t } = useTranslation();

  const initialSteps: ISteps[] = [
    {
      title: <S.StatusTitle>{t('pages.qualificationSteps.requested')}</S.StatusTitle>,
      icon: <SnippetsOutlined />,
      key: 'REQUESTED',
    },
    {
      title: <S.StatusTitle>{t('pages.qualificationSteps.sampleSubmissionPending')}</S.StatusTitle>,
      icon: <HourglassOutlined />,
      key: 'SAMPLE_SUBMISSION_PENDING',
    },
    {
      title: <S.StatusTitle>{t('pages.qualificationSteps.samplesSent')}</S.StatusTitle>,
      icon: <SendOutlined />,
      key: 'SAMPLES_SENT',
    },
    {
      title: <S.StatusTitle>{t('pages.qualificationSteps.receivedOdm')}</S.StatusTitle>,
      icon: <img src={BoxSvg} alt="Box" />,
      key: 'RECEIVED_ODM',
    },
    {
      title: <S.StatusTitle>{t('pages.qualificationSteps.testingProgress')}</S.StatusTitle>,
      icon: <FolderViewOutlined />,
      key: 'TESTING_PROGRESS',
    },
    {
      title: <S.StatusTitle>{t('pages.qualificationSteps.resultAvailable')}</S.StatusTitle>,
      icon: <TrophyOutlined />,
      key: 'RESULT_AVAILABLE',
    },
  ];
  const [steps, setSteps] = useState(initialSteps);

  const formatSteps = () => {
    const stepsCopy = initialSteps;

    stepsCopy[0].status = 'finish';
    stepsCopy[0].description = (
      <div>
        <span>
          {dayjs().format('MM.DD.YYYY ')} - {dayjs().format('hh:mm a')}
        </span>
      </div>
    );
  };

  return {
    formatSteps,
    steps,
  };
}
