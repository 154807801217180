import { CopyOutlined } from '@ant-design/icons';
import { Col, Divider, Flex } from 'antd';
import { useTranslation } from 'react-i18next';

import BoxComponent from '../../../assets/box-component.svg';
import { oneAlert } from '../../../helpers/nUtils';
import StyledIndicationTag from '../../IndicationTag';
import * as S from './styles';
import { IComponentSpecifications } from './types';

export function ComponentSpecifications(data: IComponentSpecifications) {
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.Header>
        <S.Padding>
          <img src={BoxComponent} alt="" />
          {t('pages.viewQualification.componentSpecifications.title')}
        </S.Padding>
      </S.Header>
      <S.Content>
        <Col span={24}>
          <S.InformationContent>
            {/* PART NUMBER */}
            <Col span={!data.viewAcer ? 20 : 6}>
              <Flex vertical gap={4}>
                <S.Label>{t('pages.viewQualification.componentSpecifications.partNumber')}</S.Label>
                <Flex gap={8} align="center">
                  <S.Value isPartNumber>{data.partNumber || '-'}</S.Value>
                  {data.partNumber && (
                    <CopyOutlined
                      className="copySvg"
                      onClick={() =>
                        data.partNumber &&
                        navigator.clipboard.writeText(data.partNumber).then(() => {
                          oneAlert({ type: 'info', message: t('common.copiedToClipboard') });
                        })
                      }
                    />
                  )}
                </Flex>
              </Flex>
            </Col>

            {/* Q-STATUS */}
            <Col span={!data.viewAcer ? 4 : 6}>
              <Flex vertical gap={4}>
                <S.Label>{t('pages.viewQualification.componentSpecifications.qStatus')}</S.Label>
                <StyledIndicationTag
                  text={data.qStatus || '-'}
                  style={{ justifyContent: 'flex-start', paddingInlineStart: 0 }}
                />
              </Flex>
            </Col>

            {/* COST USD */}
            {data.viewAcer && (
              <Col span={6}>
                <Flex vertical gap={4}>
                  <S.Label>{t('pages.viewQualification.componentSpecifications.costUSD')}</S.Label>
                  {data.costUsd || '-'}
                </Flex>
              </Col>
            )}
          </S.InformationContent>
        </Col>
        <Divider />
        <S.InformationContent>
          {/* CM */}
          {!data.viewAcer && (
            <Col span={!data.viewAcer ? 5 : 6}>
              <Flex vertical gap={4}>
                <S.Label>{t('pages.viewQualification.componentSpecifications.cm')}</S.Label>
                <S.Value>{data.cm || '-'}</S.Value>
              </Flex>
            </Col>
          )}
          {/* CATEGORY */}
          <Col span={!data.viewAcer ? 5 : 6}>
            <Flex vertical gap={4}>
              <S.Label>{t('pages.viewQualification.componentSpecifications.category')}</S.Label>
              <S.Value>{data.category || '-'}</S.Value>
            </Flex>
          </Col>
          {/* SPECIFIATION */}
          <Col span={!data.viewAcer ? 5 : 6}>
            <Flex vertical gap={4}>
              <S.Label>
                {t('pages.viewQualification.componentSpecifications.specification')}
              </S.Label>
              <S.Value>{data.specification || '-'}</S.Value>
            </Flex>
          </Col>
          {/* PROJECT */}
          <Col span={!data.viewAcer ? 5 : 6}>
            <Flex vertical gap={4}>
              <S.Label>{t('pages.viewQualification.componentSpecifications.project')}</S.Label>
              <S.Value>{data.project || '-'}</S.Value>
            </Flex>
          </Col>
          {/* SUPPLIER */}
          <Col span={!data.viewAcer ? 4 : 6}>
            <Flex vertical gap={4}>
              <S.Label>{t('pages.viewQualification.componentSpecifications.supplier')}</S.Label>
              <S.Value>{data.supplier || '-'}</S.Value>
            </Flex>
          </Col>
        </S.InformationContent>
        <S.InformationContent addMarginTop>
          {/* DESCRIPTION */}
          <Flex vertical gap={4}>
            <S.Label>{t('pages.viewQualification.componentSpecifications.description')}</S.Label>
            <S.Value>{data.description || '-'}</S.Value>
          </Flex>
        </S.InformationContent>
      </S.Content>
    </S.Container>
  );
}
