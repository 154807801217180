import { ExclamationCircleFilled, TruckOutlined } from '@ant-design/icons';
import { Flex, Progress, Tooltip } from 'antd';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import StyledButton from '../../components/Common/StyledButton';
import { Loading } from '../../components/Loading';
import { handleFormatValueFields } from '../../helpers/nUtils';
import theme from '../../styles/theme';
import { AwardSplitPageProps } from '../AwardSplit/types';
import ExpandableTable from '../ComponentsPrices/ExpandableTable';
import * as S from './styles';
import { useAwardDetails } from './useAwardDetails';

export function AwardDetails() {
  const {
    handleFetchDetailsAward,
    handleRenderCardsSuppliers,
    setIsOpenAward,
    setIsOpenDetails,
    handleRenderAwardCards,
    handleRenderSpecs,
    handleCalculateProjectsConcludedPercent,
    handleFinish,
    handleNavigateToAwardSplit,
    handleVerifyIfAllProjectsIsDone,
    isOpenCardAward,
    isOpenCardDetails,
    awardDetails,
    loading,
  } = useAwardDetails();

  const location = useLocation();
  const { bidId, altGroup } = location.state as AwardSplitPageProps;

  useEffect(() => {
    handleFetchDetailsAward(bidId, altGroup);
  }, []);

  return (
    <S.Container>
      {loading ? (
        <Loading />
      ) : (
        <>
          <S.ContentLeft>
            <Flex
              align="center"
              gap={8}
              style={{ fontSize: '1.75rem', color: theme.colorsDesignSystem.tagColorYellow }}
            >
              <ExclamationCircleFilled />
              <S.TitleLeftCard>{awardDetails?.altGroup}</S.TitleLeftCard>
            </Flex>
            <S.Line />
            <Flex vertical gap={8} justify="center">
              <S.Labels className="withBorder">{`${awardDetails?.projectsConcluded}/${
                awardDetails?.totalProjects
              } ${t('pages.awardDetails.labels.projects')}`}</S.Labels>
              <Progress
                percent={handleCalculateProjectsConcludedPercent(
                  awardDetails?.totalProjects || 0,
                  awardDetails?.projectsConcluded || 0
                )}
                showInfo={false}
              />
            </Flex>
            <S.ContainerCards>
              <S.CardLeftSide>
                <S.Labels>{t('pages.awardDetails.labels.category')}</S.Labels>
                <S.Labels className="normal">{awardDetails?.category || '-'}</S.Labels>
              </S.CardLeftSide>
              <S.CardLeftSide>
                <S.Labels>{t('pages.awardDetails.labels.spec')}</S.Labels>
                <Flex gap={8}>{handleRenderSpecs()}</Flex>
              </S.CardLeftSide>
              <S.CardLeftSide>
                <S.CardFromTo>
                  <S.Labels>{t('pages.awardDetails.labels.from')}</S.Labels>
                  <S.Labels className="normal">{awardDetails?.from || '-'}</S.Labels>
                  <TruckOutlined
                    style={{ fontSize: '1.2rem', color: theme.colorsDesignSystem.blue }}
                  />
                  <S.Labels>{t('pages.awardDetails.labels.to')}</S.Labels>
                  <S.Labels className="normal">{awardDetails?.to || '-'}</S.Labels>
                </S.CardFromTo>
              </S.CardLeftSide>
              <S.CardLeftSide>
                <Flex justify="space-between">
                  <Flex vertical gap={4}>
                    <S.Labels>
                      {t('pages.awardDetails.labels.startingPrice').toUpperCase()}
                    </S.Labels>
                    <S.Labels className="large">
                      {awardDetails?.startingPrice ? (
                        <Tooltip
                          title={handleFormatValueFields({
                            number: awardDetails.startingPrice,
                          })}
                        >
                          $
                          {handleFormatValueFields({
                            number: awardDetails.startingPrice,
                            notation: 'compact',
                          })}
                        </Tooltip>
                      ) : (
                        '-'
                      )}
                    </S.Labels>
                  </Flex>
                  |
                  <Flex vertical gap={4}>
                    <S.Labels>{t('pages.awardSplit.labels.totalDemands').toUpperCase()}</S.Labels>
                    <S.Labels className="large">
                      {awardDetails?.totalDemand ? (
                        <Tooltip
                          title={handleFormatValueFields({
                            number: awardDetails.totalDemand,
                            maxFactional: 0,
                            minFractional: 0,
                          })}
                        >
                          ${' '}
                          {handleFormatValueFields({
                            number: awardDetails.totalDemand,
                            notation: 'compact',
                          })}
                        </Tooltip>
                      ) : (
                        '-'
                      )}
                    </S.Labels>
                  </Flex>
                </Flex>
              </S.CardLeftSide>
            </S.ContainerCards>
          </S.ContentLeft>
          <S.ContainerRight
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '80%',
              minHeight: '85vh',
            }}
          >
            <S.ContainerCardsRight>
              <S.Labels className="large">{t('pages.awardDetails.titles.titleRightCard')}</S.Labels>
              <S.CardsContainer>
                <S.HeaderExpandableItem>
                  <ExpandableTable
                    isOpen={isOpenCardDetails}
                    onClick={() => {
                      setIsOpenDetails(!isOpenCardDetails);
                    }}
                  />
                  <Flex gap={8}>
                    <span>{t('pages.awardDetails.labels.groupDetails')}</span>
                    <span className="line">|</span>
                    <span className="colored">{awardDetails?.altGroup || '-'}</span>
                  </Flex>
                </S.HeaderExpandableItem>
                {isOpenCardDetails && (
                  // {awardDetails?.groupDetail.isOpen && (
                  <S.ContainerExpandableContent>
                    <Flex align="center" gap={8}>
                      <span className="subtitleBold">{t('pages.awardDetails.labels.total')}</span>
                      <span className="subtitle">{t('pages.awardDetails.labels.netPrices')}</span>
                      <S.Line className="noMargin" />
                    </Flex>
                    <S.GridCardsContainer>
                      <S.CardNetPrices>
                        <S.Labels className="smallTitle">
                          {t('pages.awardDetails.labels.totalWin')}
                        </S.Labels>
                        <S.Labels className="subtltleBold">
                          {awardDetails?.groupDetail.totalWin ? (
                            <Tooltip
                              title={handleFormatValueFields({
                                number: awardDetails.groupDetail.totalWin,
                              })}
                            >
                              ${' '}
                              {handleFormatValueFields({
                                number: awardDetails.groupDetail.totalWin,
                                notation: 'compact',
                              })}
                            </Tooltip>
                          ) : (
                            '-'
                          )}
                        </S.Labels>
                      </S.CardNetPrices>
                      <S.CardNetPrices>
                        <S.Labels className="smallTitle">
                          {t('pages.awardDetails.labels.totalBaseline')}
                        </S.Labels>
                        <S.Labels className="subtltleBold">
                          {awardDetails?.groupDetail.totalBaseline ? (
                            <Tooltip
                              title={handleFormatValueFields({
                                number: awardDetails.groupDetail.totalBaseline,
                              })}
                            >
                              ${' '}
                              {handleFormatValueFields({
                                number: awardDetails.groupDetail.totalBaseline,
                                notation: 'compact',
                              })}
                            </Tooltip>
                          ) : (
                            '-'
                          )}
                        </S.Labels>
                      </S.CardNetPrices>
                      <S.CardNetPrices>
                        <S.Labels className="smallTitle">
                          {t('pages.awardDetails.labels.totalFob')}
                        </S.Labels>
                        <S.Labels className="subtltleBold">
                          {awardDetails?.groupDetail.totalFob ? (
                            <Tooltip
                              title={handleFormatValueFields({
                                number: awardDetails.groupDetail.totalFob,
                              })}
                            >
                              ${' '}
                              {handleFormatValueFields({
                                number: awardDetails.groupDetail.totalFob,
                                notation: 'compact',
                              })}
                            </Tooltip>
                          ) : (
                            '-'
                          )}
                        </S.Labels>
                      </S.CardNetPrices>
                      <S.CardNetPrices>
                        <S.Labels className="smallTitle">
                          {t('pages.awardDetails.labels.impact')}
                        </S.Labels>
                        <Flex gap={8} align="center">
                          <S.Labels className="small">
                            {awardDetails?.groupDetail.impactSavingPercentage
                              ? `${handleFormatValueFields({
                                  number: awardDetails.groupDetail.impactSavingPercentage,
                                  notation: 'standard',
                                })}%`
                              : '-'}
                          </S.Labels>
                          |
                          {awardDetails?.groupDetail.impactSaving ? (
                            <Tooltip
                              title={handleFormatValueFields({
                                number: awardDetails.groupDetail.impactSaving,
                              })}
                            >
                              <S.LabelColored>
                                {handleFormatValueFields({
                                  number: awardDetails.groupDetail.impactSaving,
                                  notation: 'compact',
                                })}
                              </S.LabelColored>
                            </Tooltip>
                          ) : (
                            <S.LabelColored>-</S.LabelColored>
                          )}
                        </Flex>
                      </S.CardNetPrices>
                    </S.GridCardsContainer>
                    <Flex align="center" gap={8}>
                      <span className="subtitleBold">{t('pages.awardDetails.labels.total')}</span>
                      <span className="subtitle">{t('pages.awardDetails.labels.bidSupplier')}</span>
                      <S.Line className="noMargin" />
                    </Flex>
                    <Flex vertical gap={16}>
                      {handleRenderCardsSuppliers()}
                    </Flex>
                  </S.ContainerExpandableContent>
                )}
              </S.CardsContainer>
              <S.CardsContainer>
                <S.HeaderExpandableItem>
                  <ExpandableTable
                    isOpen={isOpenCardAward}
                    onClick={() => {
                      setIsOpenAward(!isOpenCardAward);
                    }}
                  />
                  <Flex gap={8} align="center">
                    <span>{t('pages.awardDetails.labels.award')}</span>
                    <span className="subtitle">{t('pages.awardDetails.labels.card')}</span>
                    <span className="line">|</span>
                    <span className="colored">{awardDetails?.altGroup || '-'}</span>
                  </Flex>
                </S.HeaderExpandableItem>
                {isOpenCardAward && handleRenderAwardCards()}
              </S.CardsContainer>
            </S.ContainerCardsRight>
            <S.FooterContainer>
              <StyledButton
                variant="secondary"
                disabled={handleVerifyIfAllProjectsIsDone()}
                onClick={() => {
                  handleNavigateToAwardSplit(bidId, altGroup);
                }}
              >
                {t('pages.awardDetails.buttons.previous')}
              </StyledButton>
              <StyledButton
                variant="primary"
                onClick={() => {
                  handleFinish(bidId);
                }}
              >
                {t('pages.awardDetails.buttons.finish')}
              </StyledButton>
            </S.FooterContainer>
          </S.ContainerRight>
        </>
      )}
    </S.Container>
  );
}
