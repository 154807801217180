import { Flex } from 'antd';
import { StyledLoading } from '../../../components/Common/StyledLoading';
import SimpleTable from '../../../components/Tables/SimpleTable';
import * as S from './styles';
import { useBidAllocation } from './useBidAllocation';

export function BidAllocationPage() {
  const {
    options,
    columns,
    bidAllocationData,
    isLoding,
    setDataType,
    generateSupplierColumnsPercent,
    handleChangeTable,
  } = useBidAllocation();

  return (
    <S.Container>
      <Flex align="flex-end" justify="flex-end">
        <S.StyleRadio
          options={options}
          defaultValue="QTY"
          buttonStyle="solid"
          optionType="button"
          onChange={(e) => setDataType(e.target.value)}
        />
      </Flex>
      {isLoding ? (
        <StyledLoading height={10} />
      ) : (
        <S.TablesContainer>
          <S.FirstTable>
            <SimpleTable
              striped
              columns={columns}
              dataSource={bidAllocationData}
              onChange={handleChangeTable}
              pagination={false}
              scroll={{ x: 'max-content' }}
              rowKey="altGroup"
            />
          </S.FirstTable>
          <S.SecondTable>
            <SimpleTable
              striped
              columns={[...generateSupplierColumnsPercent(bidAllocationData)]}
              dataSource={bidAllocationData}
              pagination={false}
              scroll={{ x: 'max-content' }}
              rowKey="altGroup"
            />
          </S.SecondTable>
        </S.TablesContainer>
      )}
    </S.Container>
  );
}
