import { DollarOutlined, ExclamationCircleFilled } from '@ant-design/icons';

import { Divider } from 'antd';
import { t } from 'i18next';
import * as S from './styles';
import { ItemsQuotation } from './types';

import ExclamationCircle from '../../../assets/exclamation-circle.svg';
import {
  formatNumberForTowDecimalPlaces,
  formatNumberToOrdinal,
} from '../../../helpers/FormatValues';
import { handleFormatValueFields } from '../../../helpers/nUtils';
import theme from '../../../styles/theme';

export default function StyledBidCardList(data: Array<ItemsQuotation>, isAward?: boolean) {
  return data.map((item) => {
    return (
      <S.Container key={item.category}>
        <span>{item.category}</span>
        {item.items.map((items, index) => {
          return (
            <S.ItemContainer key={crypto.randomUUID()}>
              <S.CardContainer>
                <S.CardTitle>
                  {!isAward ? (
                    !items.grossPrice ? (
                      <ExclamationCircleFilled
                        style={{ color: theme.colorsDesignSystem.tagColorYellow }}
                      />
                    ) : (
                      <img
                        src={ExclamationCircle}
                        alt="Exclamation Circle Icon"
                        style={{ width: '1.2rem' }}
                      />
                    )
                  ) : null}
                  <span className="blue">{(index + 1).toString().padStart(2, '0')}</span>
                  <S.AltGroup>{items.name}</S.AltGroup>
                </S.CardTitle>
                {items.rankingPosition && (
                  <S.Classification>
                    {formatNumberToOrdinal(items.rankingPosition || 0)}
                  </S.Classification>
                )}
              </S.CardContainer>
              {(items.grossPrice || isAward) && (
                <S.InformationContainer>
                  {items.grossPrice && (
                    <>
                      <S.InformationLabel>
                        <DollarOutlined />
                        {t('pages.bidSupplierRFQ.grossPrice')}
                      </S.InformationLabel>
                      <S.InformationValue isBlue={!isAward}>
                        ${formatNumberForTowDecimalPlaces(Number(items.grossPrice))}
                      </S.InformationValue>{' '}
                    </>
                  )}
                  {isAward && (
                    <>
                      {' '}
                      <Divider type="vertical" />
                      <S.InformationLabel>
                        {t('pages.bidSupplierRFQ.result.awardQty')}
                      </S.InformationLabel>
                      <S.InformationValue>
                        {handleFormatValueFields({
                          number: items.awardQty,
                          minFractional: 0,
                          maxFactional: 0,
                        })}
                      </S.InformationValue>
                      <Divider type="vertical" />
                      <S.InformationLabel>
                        {t('pages.bidSupplierRFQ.result.totalAward')}
                      </S.InformationLabel>
                      <S.InformationValue isBlue>
                        $
                        {handleFormatValueFields({
                          number: items.totalAward,
                          minFractional: 1,
                          maxFactional: 2,
                        })}
                      </S.InformationValue>{' '}
                    </>
                  )}
                </S.InformationContainer>
              )}
            </S.ItemContainer>
          );
        })}
      </S.Container>
    );
  });
}
