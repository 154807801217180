import { Radio } from 'antd';
import styled from 'styled-components';

export const Container = styled.main`
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.background};
  cursor: pointer;
`;

export const ContentContainer = styled.div`
  display: flex;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  /* min-width: 24.438rem; */
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border-radius: 0.75rem 0 0 0.75rem;
  padding: 1.875rem;
  border-right: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};

  hr {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border: none;
    height: 1px;
    background-color: ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  }
`;

export const TitleLeftCard = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.black85};
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  > :first-child {
    margin-top: 1.5rem;
  }
`;

export const Labels = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};

  &.withBorder {
    margin-top: 0.5rem;
  }

  &.small {
    font-size: 0.75rem;
  }

  &.normal {
    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }

  &.large {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }

  &.alert {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colorsDesignSystem.red_secondary};
    margin-top: -0.75rem;
  }
`;

export const CardLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  height: 4.5rem;
  gap: 0.5rem;
  padding: 1rem 1.5rem 1rem 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.header};
`;

export const CardFromTo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  padding: 0.5rem;
  border-radius: 0.45rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.backgroundDestiny};
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  justify-content: space-between;
`;

export const ContainerContentRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2.5rem 1.5rem 0 1.5rem;
`;

export const ContainerCardsRight = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.3rem;
  gap: 1.5rem;
  justify-content: center;

  @media (max-width: 1280px) {
    gap: 0.75rem;
  }
`;

export const CardInputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  height: 4.87rem;
  width: 19.1rem;
  gap: 0.65rem;
  /* min-width: 18.12rem; */
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  padding: 1rem 1.5rem 1rem 1.5rem;
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContainerCardInputs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.56rem;
`;

export const ContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3rem;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  padding: 1rem 2.5rem 1rem 2.5rem;
  border-bottom-right-radius: 0.75rem;
`;

export const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked) span {
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
  }
`;
