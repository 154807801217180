import { Flex, Row } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  margin-bottom: 1.5rem;
`;

export const Padding = styled(Flex)`
  align-items: center;
  gap: 0.75rem;
  padding: 1.2rem 1.5rem;
`;

export const Header = styled(Flex)`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const Content = styled(Flex)`
  flex-direction: column;
  padding: 2rem;
  .copySvg {
    width: 1rem;
    height: 1rem;
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
    cursor: pointer;
  }
`;

export const InformationContent = styled(Row)<{ addMarginTop?: boolean }>`
  width: 100%;
  margin-top: ${({ addMarginTop }) => addMarginTop && '2rem'};
`;

export const Label = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const Value = styled.span<{ isPartNumber?: boolean }>`
  font-size: ${({ isPartNumber }) => (isPartNumber ? '1.125rem' : '1rem')};
  font-weight: ${({ isPartNumber }) => (isPartNumber ? 700 : 600)};
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;
