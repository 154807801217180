import { ExclamationCircleFilled, FileAddOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import ExclamationIcon from '../../../../assets/exclamation-circle.svg';
import * as S from './styles';
import { IQuotationCard } from './types';

export function QuotationCard({ hasFile, onClickButton, onClickTitle }: IQuotationCard) {
  const { t } = useTranslation();
  return (
    <S.Container>
      <Flex justify="space-between" style={{ width: '100%' }}>
        <Flex gap={8} align="center" justify="center">
          <FileAddOutlined className="uploadIcon" />
          <S.Title hasFiles={hasFile} onClick={onClickTitle}>
            {t('pages.bidSupplierRFQ.quotation.quotation')}
          </S.Title>
        </Flex>
        {hasFile ? (
          <img src={ExclamationIcon} alt="Exclamation File" />
        ) : (
          <ExclamationCircleFilled className="exclamationFile" />
        )}
      </Flex>
      <S.UploadButton variant="secondary" onClick={onClickButton}>
        {t('pages.bidSupplierRFQ.quotation.uploadFile')}
      </S.UploadButton>
    </S.Container>
  );
}
