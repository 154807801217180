import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { handleFormatValueFields, oneAlert } from '../../../../helpers/nUtils';
import { getURI } from '../../../../helpers/utils';
import { api } from '../../../../services/api';
import { services } from '../../../../services/services';
import theme from '../../../../styles/theme';
import { IParams } from '../types';
import { IData, IGraphData, IImpactSavingData } from './types';

export function useImpactSaving() {
  const { t } = useTranslation();
  const { id: idBid } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [impactSavingData, setImpactSavingData] = useState<IImpactSavingData[]>([]);
  const [graphData, setGraphData] = useState<IGraphData[]>([]);

  const formatDataGrah = (data: IData[]) => {
    const formattedData: IGraphData[] = data.map((item) => {
      return {
        supplier: item.supplier,
        percentage: item.percentage,
        graphData: [
          {
            name: t('pages.awards.managementBid.impactSavingWin.baseline'),
            value: [0, item.totalBaseline],
            label: `$${handleFormatValueFields({ number: item.totalBaseline })}`,
          },
          {
            name: t('pages.awards.managementBid.impactSavingWin.impact'),
            value: [item.totalWin, item.totalBaseline].sort((a, b) => a - b),
            label: `$${handleFormatValueFields({ number: item.totalImpact })}`,
          },
          {
            name: t('pages.awards.managementBid.impactSavingWin.totalWin'),
            value: [0, item.totalWin],
            label: `$${handleFormatValueFields({ number: item.totalWin })}`,
          },
        ],
      };
    });

    return formattedData;
  };

  const fetchData = async (params: IParams) => {
    try {
      setIsLoading(true);
      const { status, data } = await api.get<IImpactSavingData>(
        getURI(`${services.rfq}/bid/management/impactSavingWin`, { ...params })
      );

      if (status === 200) {
        setGraphData(formatDataGrah(data.impactSavingWin));
      } else setGraphData([]);
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  const handleColorBars = (name: string, percentage: number) => {
    if (name === t('pages.awards.managementBid.impactSavingWin.baseline')) {
      return theme.colorsDesignSystem.newTablesColors.borderColor;
    }
    if (name === t('pages.awards.managementBid.impactSavingWin.impact')) {
      return percentage < 0
        ? theme.colorsDesignSystem.chartColors.green
        : theme.colorsDesignSystem.red_secondary;
    }
    if (name === t('pages.awards.managementBid.impactSavingWin.totalWin')) {
      return theme.colorsDesignSystem.chartColors.acqua;
    }
    return theme.colorsDesignSystem.chartColors.blue;
  };

  const CustomizedTicks = (props: any) => {
    const { payload, x, y } = props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={4} textAnchor="end" fill="#666">
          {handleFormatValueFields({
            number: payload.value,
            maxFactional: 1,
            minFractional: 0,
            notation: 'compact',
          })}
        </text>
      </g>
    );
  };

  return {
    isLoading,
    fetchData,
    graphData,
    handleColorBars,
    CustomizedTicks,
  };
}
