import styled from 'styled-components';

export const Container = styled.main`
  margin-top: 2.938rem;
`;

export const LinkTable = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-left: 1rem;
  line-height: 1.375rem;
  span {
    font-weight: 700;
    max-width: 50ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
`;
