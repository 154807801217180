import { InfoCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';

interface cardsQuantities {
  isSelected?: boolean;
  percentErro?: boolean;
}

export const Container = styled.main<cardsQuantities>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 1rem;
  gap: 1rem;
`;

export const ItemContainer = styled.div<cardsQuantities>`
  display: flex;
  align-items: center;

  justify-content: space-around;
  background-color: ${({ isSelected, theme }) =>
    isSelected
      ? `${theme.colorsDesignSystem.cardBidSelected}`
      : `${theme.colorsDesignSystem.tag_background}`};
  padding: 1rem 1.1rem;
  border: 0.06rem solid
    ${({ isSelected, theme }) =>
      isSelected
        ? `${theme.colorsDesignSystem.cardBorder}`
        : `${theme.colorsDesignSystem.tertiaryBorder}`};
  border-radius: 0.8rem;
  line-height: 1.5rem;
  gap: 1.5rem;
  width: 100%;
  cursor: pointer;

  :hover {
    border: 0.04rem solid ${({ theme }) => theme.colorsDesignSystem.primary};
    box-shadow: 0 0 2.5px 0.3px ${({ theme }) => theme.colorsDesignSystem.primary};
  }
`;

export const Label = styled.span`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
  text-transform: uppercase;
`;

export const PartNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 0.06rem solid ${({ theme }) => theme.colorsDesignSystem.boxshadowTooltipChart};
  padding-right: 2rem;
  width: 20%;
`;

export const PartNumberValue = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colorsDesignSystem.primary};
  @media (max-width: 1360px) {
    font-size: 1.2rem;
  }
`;

export const SourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  width: 10%;
`;

export const SupplierContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 0.06rem solid ${({ theme }) => theme.colorsDesignSystem.boxshadowTooltipChart};
  padding-left: 1rem;
  padding-right: 2rem;
  width: 30%;
`;

export const SupllierValue = styled.span<cardsQuantities>`
  font-size: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
`;

export const PercentValue = styled.span<cardsQuantities>`
  font-size: 1.25rem;
  font-weight: 600;
  color: ${({ percentErro, theme }) =>
    percentErro ? `${theme.colorsDesignSystem.red}` : `${theme.colorsDesignSystem.text}`};
`;

export const MessageContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 0 1rem 0 1rem;
`;

export const StyledIcon = styled(InfoCircleFilled)`
  color: ${({ theme }) => theme.colorsDesignSystem.red};
`;

export const SpanX = styled.span`
  color: ${({ theme }) => theme.colorsDesignSystem.cardBorder};
`;
