import styled from 'styled-components';

export const Classification = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  padding: 0;
  border-radius: 3.125rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25rem;
`;
