import { Modal } from 'antd';
import styled from 'styled-components';
import theme from '../../../styles/theme';

interface FooterComponent {
  footer: React.ReactNode;
  noPadding: boolean;
  noMargin: boolean;
}

export const ModalStyled = styled(Modal)<FooterComponent>`
  .ant-modal-header {
    padding: 1rem 1.5rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background: ${(props) => props.theme.colors.link};
  }

  .ant-modal-title {
    color: ${(props) => props.theme.colors.white};
  }
  .ant-modal-content {
    padding: ${({ footer }) => (footer ? '0rem 0rem 1rem 0rem' : '0rem 0rem 1rem 0rem')};
  }

  .ant-modal-close-x {
    .anticon svg {
      fill: ${(props) => props.theme.colors.white};
    }
  }
`;
export const ModalBodyContainer = styled.div<FooterComponent>`
  padding: ${({ footer, noPadding }) =>
    footer ? '0rem 1.5rem 1rem 1.5rem' : noPadding ? '0' : '0rem 1.5rem 0rem 1.5rem'};
  margin: ${({ noMargin }) => (noMargin ? '0' : '0.625rem')};
  box-sizing: content-box;
  overflow-y: auto;
`;
export const ModalFooterContainer = styled.div<FooterComponent>`
  border-top: ${({ footer }) => (footer ? `0.063rem solid ${theme.colors.gray_border}` : 'none')};
  padding: ${({ footer }) => (footer ? '0.625rem 1.5rem 0rem 1.5rem' : '0rem 1.5rem 0rem 1.5rem')};
`;
