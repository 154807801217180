import {
  FilterOutlined as AntFilterOutlined,
  SearchOutlined as AntSearchOutlined,
} from '@ant-design/icons';
import { Table as AntTable, Tag as AntTag, Typography } from 'antd';
import styled from 'styled-components';

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Tag = styled(AntTag)`
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.danger};
  margin-top: 4px;
`;
export const TagLocalPPB = styled(AntTag)`
  color: ${({ theme }) => theme.colors.shape};
  background: ${({ theme }) => theme.colors.ppb};
`;

export const TagFilter = styled(AntTag)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.prim};
  border-color: ${({ theme }) => theme.colors.border};
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  margin-right: 20px;

  .tag-title {
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray_light};
  }

  .tag-value {
    font-size: 14px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text};
  }

  .anticon {
    position: absolute;
    top: -7px;
    right: -10px;
    background-color: #fff;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 4px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    svg {
      color: ${({ theme }) => theme.colors.prim};
    }
  }
`;

export const TagCountSubComponent = styled(AntTag)`
  color: ${({ theme }) => theme.colors.shape};
  background: ${({ subcomponent, theme }) =>
    subcomponent ? theme.colors.blue : theme.colors.blue};
`;

export const ContainerFilteredTags = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.938rem;
  gap: 0.375rem;
  margin-bottom: 1rem;
`;

export const ContainerViewsAndTags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 56px;
`;

export const HR = styled.hr`
  margin: 16px 0 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  border-bottom: 0;
`;

export const SpanSmLight = styled.span`
  color: ${({ theme }) => theme.colors.gray_light};
  font-size: 13px;
  padding-right: 8px;
`;

export const SpanBlue = styled.span`
  color: ${({ theme }) => theme.colors.blue};
`;

export const SpanRed = styled.span`
  color: #ff0000;
`;

export const SpanBlueBig = styled.span`
  color: ${({ theme }) => theme.colors.blue} !important;
  font-weight: 500 !important;
  font-size: 20px !important;
`;

export const NoData = styled.div`
  width: 100%;
  padding: 8px 0;
  text-align: center;
  font-weight: 500;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  background-color: #fff;
  color: ${({ theme }) => theme.colors.title};
`;

export const HeaderSearch = styled.div`
  width: 160px;
  padding: 8px;

  > input {
    margin-bottom: 2px;
    display: block;
  }

  .ant-form-item {
    margin: 0;
  }

  .ant-checkbox-wrapper {
    display: flex;
    margin: 0 0 4px 0;
  }

  .input-container {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    margin-bottom: 8px;
    padding-bottom: 2px;
  }

  .ant-space {
    justify-content: space-between;
    width: 100%;
  }
`;

export const SearchOutlined = styled(AntSearchOutlined)`
  color: ${({ theme, filtered }) =>
    filtered === 'true' ? theme.colors.blue_secondary : theme.colors.link};
`;
export const FilterOutlined = styled(AntFilterOutlined)`
  color: ${({ theme, filtered }) =>
    filtered === 'true' ? theme.colors.blue_secondary : theme.colors.link};
`;

export const ScoreColorSpan = styled.span`
  color: ${({ score }) => {
    if (score < 42) {
      return '#FF0000';
    }

    if (score > 42) {
      return '#FF9900';
    }

    return '#0B5394';
  }};
`;

export const ScoreColorSpanNew = styled.span`
  color: ${({ score, theme }) => {
    if (score === 'max') {
      return theme.colors.gray_light;
    }

    if (score === 'min') {
      return theme.colors.red;
    }

    if (score === 'ok') {
      return theme.colorsDesignSystem.chartColors.blue;
    }

    //actual
    return theme.colorsDesignSystem.grayAcer;
  }};
`;

export const PageText = styled.span`
  background: transparent;
  border: 0;

  color: ${({ page }) => {
    if (page === 0) {
      return '#ccc!important';
    }

    return '#000!important';
  }};
`;

export const ScoreColorDiv = styled.div`
  color: ${({ score }) => {
    if (score < 42) {
      return '#FF0000';
    }

    if (score > 42) {
      return '#FF9900';
    }

    return '#0B5394';
  }};
`;

export const Table = styled(AntTable)`
  .ant-table {
    background-color: transparent;
  }

  .ant-table-content {
    table {
      border-spacing: 0 4px;

      thead,
      th {
        font-weight: 500;
        padding: 8px 16px;

        &::before {
          display: none;
        }
      }

      tbody > tr {
        background-color: ${({ theme }) => theme.colors.shape};

        td.ant-table-cell,
        td.ant-table-column-sort {
          color: ${({ theme }) => theme.colors.text_dark};
          background-color: ${({ theme }) => theme.colors.shape};
          border-bottom: 1px solid ${({ theme }) => theme.colors.border};
          border-top: 1px solid ${({ theme }) => theme.colors.border};
          padding: 8px 16px;
          font-weight: 500;

          &:first-child {
            border-bottom-left-radius: 8px;
            border-left: 1px solid ${({ theme }) => theme.colors.border}!important;
            border-top-left-radius: 8px;
          }

          &:last-child {
            border-bottom-right-radius: 8px;
            border-right: 1px solid ${({ theme }) => theme.colors.border}!important;
            border-top-right-radius: 8px;
          }
        }
      }

      tr.row-first-level {
        background-color: ${({ theme }) => theme.colors.tableDark};
        td.ant-table-cell,
        td.td.ant-table-column-sort {
          background-color: ${({ theme }) => theme.colors.tableDark};
          color: ${({ theme }) => theme.colors.shape};
          font-weight: 500;
          padding: 10px 8px;
        }

        &:hover > td,
        td.td.ant-table-column-sort {
          background-color: ${({ theme }) => theme.colors.tableDark};
        }
      }

      tr.row-sec-level {
        background-color: ${({ theme }) => theme.colors.tableLight};
        td.ant-table-cell,
        td.td.ant-table-column-sort {
          background-color: ${({ theme }) => theme.colors.tableLight};
          color: ${({ theme }) => theme.colors.shape};
          font-weight: 500;
          padding: 10px 8px;
        }

        &:hover > td,
        td.td.ant-table-column-sort {
          background-color: ${({ theme }) => theme.colors.tableLight};
        }
      }
    }
  }
`;

export const TableWithChildren = styled(AntTable)`
  .ant-table {
    background-color: transparent;
  }

  .ant-table-content {
    table {
      border-spacing: 0 4px;

      thead,
      th {
        font-weight: 500;
        padding: 8px 16px;

        &::before {
          display: none;
        }
      }

      tbody > tr,
      tbody > tr.data-row-editing {
        background-color: ${({ theme }) => theme.colors.shape};
        &:hover > td {
          background-color: ${({ theme }) => theme.colors.shape};
        }

        td {
          color: ${({ theme }) => theme.colors.text_dark};
          border-bottom: 1px solid ${({ theme }) => theme.colors.border};
          border-top: 1px solid ${({ theme }) => theme.colors.border};
          padding: 8px 8px;
          font-weight: 500;

          &:first-child {
            border-bottom-left-radius: 8px;
            border-left: 1px solid ${({ theme }) => theme.colors.border}!important;
            border-top-left-radius: 8px;
          }

          &:last-child {
            border-bottom-right-radius: 8px;
            border-right: 1px solid ${({ theme }) => theme.colors.border}!important;
            border-top-right-radius: 8px;
          }

          .ant-form-item-control-input-content {
            color: #000;
          }
        }
      }

      tbody > tr.partType {
        background-color: ${({ theme }) => theme.colors.tableLight};
        color: #fff;

        > td {
          background-color: ${({ theme }) => theme.colors.tableLight};
          color: #fff;
        }

        &:hover,
        &:hover > td {
          background-color: ${({ theme }) => theme.colors.tableLight};
          color: #fff;
        }

        &.data-row-editing {
          background-color: ${({ theme }) => theme.colors.tableLight};
          color: #fff;

          .ant-form-item-control-input-content {
            background-color: ${({ theme }) => theme.colors.tableLight};
            color: #fff;
          }

          &:hover > td {
            background-color: ${({ theme }) => theme.colors.tableLight};
            color: #fff;
          }
        }

        .ant-form-item-control-input-content {
          color: #fff;
        }
      }

      tr.ant-table-row-level-0 {
        background-color: ${({ theme }) => theme.colors.tableDark};

        td,
        td.td.ant-table-column-sort {
          background-color: ${({ theme }) => theme.colors.tableDark};
          color: ${({ theme }) => theme.colors.shape};
          font-weight: 500;
        }

        &:hover > td,
        td.td.ant-table-column-sort {
          background-color: ${({ theme }) => theme.colors.tableDark};
        }

        &.data-row-editing {
          .ant-form-item-control-input-content {
            color: #fff;
          }

          &:hover > td {
            background-color: ${({ theme }) => theme.colors.tableDark};
          }
        }

        .ant-form-item-control-input-content {
          color: #fff;
        }
      }
    }

    .ant-table-row-level-0 {
      td:nth-child(1) {
        .ant-table-row-expand-icon {
          float: left;
        }
      }
    }

    .ant-table-row-level-1 {
      td {
        font-weight: 500;
      }

      td:nth-child(1) {
        .ant-table-row-expand-icon {
          float: right;
        }
      }
    }
  }
`;

export const CellValue = styled(Typography)`
  color: ${({ theme }) => theme.colors.text};
  font-weight: 500;
`;

export const CellEdited = styled(Typography)`
  color: ${({ theme }) => theme.colors.text_blue};
  font-weight: 500;
`;

export const CellDisabled = styled(Typography)`
  color: ${({ theme }) => theme.colors.gray_light};
  font-weight: 500;
`;

export const TextElipsed = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
  text-align: left;
`;
