import styled from 'styled-components';

export const HeaderExpandableItem = styled.div`
  padding: 1.125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
`;

export const Labels = styled.p<{ positive?: boolean; bold?: boolean }>`
  font-size: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  font-weight: 300;
  margin: 0;

  &.title {
    font-size: 1.5rem;
    font-weight: bold;
  }

  &.labelLarge {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colorsDesignSystem.input_labels};
  }

  &.position {
    font-size: 1.125rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }

  &.labelMedium {
    font-size: 0.75rem;
    font-weight: ${({ bold }) => (bold ? '700' : 'normal')};
    color: ${({ bold, theme }) =>
      bold ? theme.colorsDesignSystem.text : theme.colorsDesignSystem.inputLabelColor};
  }

  &.labelValues {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }

  &.labelSemiBold {
    font-size: 0.75rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  }

  &.totalSemiBold {
    font-size: 0.85rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }

  &.totalLarge {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 0.82531rem;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }

  &.labelColored {
    font-size: 0.875rem;
    font-weight: bold;
    color: ${({ theme, positive }) =>
      positive
        ? theme.colorsDesignSystem.red
        : theme.colorsDesignSystem.rfqIndicationTagColors.greenFont};
  }
`;

export const ContainerExpandableContent = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

export const ContainerAwardCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  padding: 1.5rem;
`;

export const AwardCardInside = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  padding: 1rem 1.5rem 1rem 1.5rem;
`;

export const AwardCardInsideLeft = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const LineDashed = styled.hr`
  border: none;
  height: 0;
  border: 1px dashed ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const AwardCardInsideRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  margin-left: 1rem;
  gap: 1rem;
`;

export const CardPosition = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  border-radius: 2.34375rem;
  padding: 0rem 0.28125rem;
  height: 1.64063rem;
  width: 3rem;
`;

export const ContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 2.375rem;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.header};
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const ContainerValuesFooter = styled.div`
  display: flex;
  flex-direction: row;
  width: 55%;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.6rem;
`;

export const ContainerFooterProjectCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1rem;

  span {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colorsDesignSystem.input_labels};
    font-weight: 400;
    line-height: normal;

    &.destak {
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.375rem;
      color: ${({ theme }) => theme.colorsDesignSystem.text};
    }
  }
`;
