import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Flex, Radio } from 'antd';
import StyledIndicationTag from '../../../../components/IndicationTag';
import checked from '../../../../assets/exclamation-circle.svg';
import { ShareProportionData } from '../../types';
import * as S from './styles';

export function useShareProportionCard(click: React.Dispatch<React.SetStateAction<string>>) {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState<string>('0');

  const handleItemClick = (id: string) => {
    setSelectedItem(id);
  };

  const handleGetCardSelected = (
    dataShareProportion: Array<ShareProportionData>
  ): ShareProportionData | undefined => {
    return dataShareProportion.find((_, index) => index.toString() === selectedItem);
  };

  const renderCartItems = (dataShareProportion: Array<ShareProportionData>) => {
    const cardSelected = handleGetCardSelected(dataShareProportion);
    return (
      <>
        <S.MessageContainer>
          <Alert
            style={{ width: '100%' }}
            type={cardSelected?.match ? 'info' : 'error'}
            showIcon
            icon={cardSelected?.match ? <img src={checked} alt="" /> : <S.StyledIcon />}
            message={
              cardSelected?.match
                ? t('pages.financePage.shareProportion.matchValuesMessage')
                : t('pages.financePage.shareProportion.didMatchMessage')
            }
          />
        </S.MessageContainer>
        <S.Container>
          {dataShareProportion.map((item, index) => {
            const itemId = crypto.randomUUID();
            return (
              <S.ItemContainer
                key={itemId}
                onClick={() => {
                  handleItemClick(index.toString());
                  click(item.partNumber);
                }}
                isSelected={index.toString() === selectedItem}
              >
                <S.PartNumberContainer>
                  <Flex>
                    <Radio checked={index.toString() === selectedItem} />
                    <S.Label>{t('pages.financePage.shareProportion.partNumber')}</S.Label>
                  </Flex>
                  <S.PartNumberValue>{item.partNumber}</S.PartNumberValue>
                </S.PartNumberContainer>
                <S.SourceContainer>
                  <S.Label>{t('pages.financePage.shareProportion.source')}</S.Label>
                  <StyledIndicationTag style={{ maxWidth: '2.375rem' }} text={item.source} />
                </S.SourceContainer>
                <S.SupplierContainer>
                  <S.Label>{t('pages.financePage.shareProportion.supplier')}</S.Label>
                  <S.SupllierValue>{item.supplier}</S.SupllierValue>
                </S.SupplierContainer>
                <Flex justify="center" gap={18} align="center">
                  <Flex vertical>
                    <S.Label>{t('pages.financePage.shareProportion.quotes')}</S.Label>
                    <S.PercentValue percentErro={!item.match}>{item.porcentage}%</S.PercentValue>
                  </Flex>
                  <S.SpanX>X</S.SpanX>
                  <Flex vertical align="center">
                    <S.Label>{t('pages.financePage.shareProportion.shareProportion')}</S.Label>
                    <S.PercentValue>
                      {item.shareProportionPorcentage ? `${item.shareProportionPorcentage}%` : '-'}
                    </S.PercentValue>
                  </Flex>
                </Flex>
              </S.ItemContainer>
            );
          })}
        </S.Container>
      </>
    );
  };

  return {
    renderCartItems,
  };
}
