import { createGlobalStyle } from 'styled-components';
import AcerFocoBlack from '../assets/fonts/AcerFoco-Black.ttf';
import AcerFocoBold from '../assets/fonts/AcerFoco-Bold.ttf';
import AcerFocoLight from '../assets/fonts/AcerFoco-Light.ttf';
import AcerFocoRegular from '../assets/fonts/AcerFoco-Regular.ttf';
import AcerFocoSemibold from '../assets/fonts/AcerFoco-Semibold.ttf';

export const GlobalStyle = createGlobalStyle`

  @font-face {
  font-family: 'AcerFoco';
  src: url(${AcerFocoLight}) format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
  }

  @font-face {
  font-family: 'AcerFoco';
  src: url(${AcerFocoRegular}) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  }

  @font-face {
  font-family: 'AcerFoco';
  src: url(${AcerFocoSemibold}) format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: auto;
  }

  @font-face {
  font-family: 'AcerFoco';
  src: url(${AcerFocoBold}) format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: auto;
  }

  @font-face {
  font-family: 'AcerFoco';
  src: url(${AcerFocoBlack}) format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: auto;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    min-height: 100vh;
    background: ${({ theme }) => theme.colors.background}
  }

  ::-webkit-scrollbar {
    width: 1.125rem;
  }
    ::-webkit-scrollbar-track-piece {
      background-color: transparent;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0.625rem 0.625rem ${({ theme }) => theme.colorsDesignSystem.grayBorder};
      border: solid 0.375rem transparent;
      border-radius: 624rem;
    }
    ::-webkit-scrollbar-thumb {
      background: transparent;
      box-shadow: inset 0 0 0.625rem 0.625rem ${({ theme }) =>
        theme.colorsDesignSystem.placeHolder};
      border: solid 0.375rem transparent;
      -webkit-border-radius: 624rem;
      border-radius: 624rem;
    }

  #root > .ant-layout {
    min-height: 100vh;
    margin: 0 auto;
  }

  .drawer-menu {
    .ant-drawer-body {
      padding: 50!important;
      overflow-x: hidden;

    }

    .ant-drawer-content-wrapper {
      max-width: 255px;
    }

    .ant-drawer-header {
      padding: 16px;
    }

    .ant-drawer-header-title {
      flex-direction: row-reverse;

      .ant-drawer-close {
        margin-right: 0;
      }
    }
  }

  .side-filter {

    .ant-drawer-header-title {
      flex-direction: row-reverse;

      .ant-drawer-close{
        margin-right: 0;
      }
    }

    .ant-drawer-header {
      padding: 16px 24px;
    }
  }

  // Table
   .ant-table-small .ant-table-thead > tr > th,
  .ant-table-thead > tr > th,
  .ant-table-thead > tr > th.ant-table-column-sort
  {
    background-color: #f8f8f8 !important;
    color: ${(props) => props.theme.colors.title};
    font-weight: 500;

  }

  /* th, td.ant-table-cell  {
    padding: 0 !important;

  } */



  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .my-4 {
    margin: 4px 0;
  }

  .content {
    background-color: ${(props) => props.theme.background};
    min-height: calc(100vh - 114px);
    padding: 0;
  }

  .bg-white {
    background-color: ${(props) => props.theme.colors.shape};
  }

  .ant-page-header-heading {
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    padding: 4px 0 8px;
    margin-bottom: 8px;

    .ant-page-header-heading-title {
      font-weight: 500;
      color: ${(props) => props.theme.colors.title};
    }
  }

  //Checkbox
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.link}!important;
    border-color: ${({ theme }) => theme.colors.link}!important;
  }
  .ant-checkbox .ant-checkbox-inner::after {
    background-color: ${({ theme }) => theme.colors.link}!important;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.colors.link}!important;
  }

  // Switch
  .ant-switch-checked {
    background-color: ${(props) => props.theme.colors.link} !important;
  }

  // Links
  a {

    color: ${(props) => props.theme.colors.link};
    &:hover {
      color: ${(props) => props.theme.colors.sec};
    }
  }

  a.ant-typography, .ant-typography a {
    color: ${({ theme }) => theme.colors.link};

    &:hover {
      color: ${({ theme }) => theme.colors.sec};
      text-decoration: none;
    }

    & > svg {
      margin-left: 4px;
    }
  }

  .link {
    cursor: pointer;
    color: ${(props) => props.theme.colors.link};
  }

  /* .ant-btn {
    border-radius: 0.25rem;
    color: ${(props) => props.theme.colors.prim};
    border-color: ${(props) => props.theme.colors.prim};
  } */

  /* .ant-btn.ant-btn-circle {
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > svg {
      font-size: 18px;
    }
  } */

  /* .ant-btn:hover, .ant-btn:focus {
    filter: brightness(0.8);
    border-color: ${(props) => props.theme.colors.prim_light};
  }

  :where(.css-dev-only-do-not-override-14wwjjs).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: ${(props) => props.theme.colors.prim_light} ;
    border-color: ${(props) => props.theme.colors.prim_light} ;
} */

  /* .ant-btn-primary {
    background-color: ${(props) => props.theme.colors.prim} ;
    border-color: ${(props) => props.theme.colors.prim};
    color: ${(props) => props.theme.colors.white};
  } */

  /* .ant-btn-primary:hover, .ant-btn-primary:focus {
    background-color: ${(props) => props.theme.colors.prim_light} !important;
    border-color: ${(props) => props.theme.colors.prim_light};
    color: ${(props) => props.theme.colors.text_light}!important;
  }

  .ant-btn-dangerous {
    border-color: ${(props) => props.theme.colors.danger} ;
    color: ${(props) => props.theme.colors.danger} ;
  }

  .ant-btn-dangerous:hover .ant-btn-dangerous:focus {
    border-color: ${(props) => props.theme.colors.red} !important;
    color: ${(props) => props.theme.colors.red} !important;
  }

  .ant-btn-link {
    border: none;

  } */

  /* .ant-btn-link:hover, .ant-btn-link:focus {
    border: none;
  } */

  // Breadcrumb
  .ant-breadcrumb {
    margin: 0;
  }

  .breadcrumb-filter {
    margin: 16px;
  }

  .filter-page {
    padding: 0;
  }

  .btn-csv{
    a {
      display: inline-flex;
      bottom: 0;
      line-height: 18px;
    }
  }


  .rc-virtual-list-scrollbar-thumb{
    background-color: ${(props) => props.theme.colors.black}!important;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: ${(props) => props.theme.colors.white};
  }

  .ant-select-selection-item,
  .ant-picker-input > input,
  .ant-input {
    font-weight: 500;
    color: ${(props) => props.theme.colors.text};
  }

  .ant-input::placeholder, .ant-select-selection-placeholder {
    font-weight: 500;
    color: ${(props) => props.theme.colors.gray_light};
  }

  .ant-form-item-label > label{
    color: ${(props) => props.theme.colors.text};
    font-weight: 500;
  }

  .ant-form-vertical .ant-form-item-label {
    padding: 0 0 4px;
  }
  .ant-form-item-explain.ant-form-item-explain-error, .ant-form-item-explain-error{
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .ant-drawer-close {
    color: ${(props) => props.theme.colors.prim} !important;

    &:hover {
      color: ${(props) => props.theme.colors.sec} !important;
    }
  }

  .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.80);
  }

  .ant-modal-content {
    border-radius: 8px;
    //min-width: 1200px;
  }

  .ant-modal-wrap {
    z-index: 1001 !important;
  }

  .ant-dropdown {
    z-index: 1000 !important;
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: ${(props) => props.theme.colors.prim};
  }

  .ant-upload-list {
    display: none;
  }

  .ant-upload-drag-container{
    padding: 8px 16px;
  }

  .ant-tabs-nav-operations{
    display: none!important;
  }

  .ant-tabs-nav-list{
    width: 100%;
    margin-bottom: 10px;
  }
  .ant-tabs-tab {
    border-right: 2px solid ${(props) => props.theme.colors.border};
    padding-right: 24px;
    width: 50%;
    font-weight: 500;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    padding-left: 24px;
    margin: 0;
    border-right: none;
  }

  // Tags
  span.ant-tag {
    border-radius: 8px;
  }

  .ant-badge-status-dot {
    width: 8px;
    height: 8px;
  }

  .ant-spin {
    color: ${({ theme }) => theme.colors.prim};
  }
  .ant-spin-text {
    margin-top: 18px;
  }

  .ant-form-item-explain-error {
    font-size: 12px;
  }

  .ant-dropdown-menu-item {
    button {
      width: 100%;
    }
  }
`;
