import { Divider, Empty, Flex } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { StyledLoading } from '../../../../components/Common/StyledLoading';
import { handleFormatValueFields } from '../../../../helpers/nUtils';
import theme from '../../../../styles/theme';
import { IParams } from '../types';
import * as S from './styles';
import { useImpactSaving } from './useImpactSavingWin';

export function ImpactSavingWin({ altGroup, category, supplier, idBid }: IParams) {
  const { t } = useTranslation();
  const { fetchData, graphData, isLoading, handleColorBars, CustomizedTicks } = useImpactSaving();

  useEffect(() => {
    fetchData({ altGroup, category, supplier, idBid });
  }, [altGroup, category, supplier]);

  return (
    <S.Container>
      <S.TitleContainer>
        <b>{t('pages.awards.managementBid.impactSavingWin.title')}</b>
        <Divider type="vertical" />
        <span>USD</span>
      </S.TitleContainer>
      <S.Content>
        {isLoading ? (
          <StyledLoading height={20} />
        ) : graphData.length > 0 ? (
          <>
            {graphData.map((graph) => {
              return (
                <S.GraphContent vertical key={graph.supplier}>
                  <Flex justify="space-between">
                    <S.Supplier>{graph.supplier}</S.Supplier>
                    {graph.percentage !== null && (
                      <S.Percentage value={graph.percentage}>
                        {handleFormatValueFields({ number: graph.percentage })}%
                      </S.Percentage>
                    )}
                  </Flex>
                  <ResponsiveContainer width="100%" height={220}>
                    <BarChart
                      width={380}
                      height={220}
                      data={graph.graphData}
                      margin={{
                        left: 0,
                        right: 26,
                        top: 40,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3 " />
                      <XAxis dataKey="name" />
                      <YAxis
                        tickLine={false}
                        tick={<CustomizedTicks />}
                        type="number"
                        domain={([dataMin, dataMax]) => {
                          const valorForMultiple = dataMax < 1000 ? 1000 : 1500;
                          const min = Math.floor(dataMin / 1000) * 1000;
                          let max = Math.ceil(dataMax / 1000) * valorForMultiple;
                          max = max === dataMax ? max + 1000 : max;
                          return [min, max];
                        }}
                      />
                      <Bar dataKey="value" radius={[4, 4, 0, 0]} barSize={64}>
                        <LabelList dataKey="label" fill={theme.colors.text} position="top" />
                        {graph.graphData.map((entry) => (
                          <Cell
                            key={entry.name}
                            fill={handleColorBars(entry.name, graph.percentage)}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </S.GraphContent>
              );
            })}
          </>
        ) : (
          <Empty />
        )}
      </S.Content>
    </S.Container>
  );
}
