import { PaperClipOutlined, SyncOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableAction } from '../../../../components/Tables/TableDropdownAction';
import { getSortOrder } from '../../../../helpers/utils';

import { oneAlert } from '../../../../helpers/nUtils';
import * as S from './styles';
import { IDatasheet, IParams } from './types';

export function useDatasheet() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [datasheetData, setDatasheetData] = useState<IDatasheet[]>([]);

  const initialParams: IParams = {
    sortField: 'dateHour' as Key,
    sortDirection: 'asc',
  };
  const [params, setParams] = useState<IParams>(initialParams);

  const handleChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IDatasheet[]>
  ) => {
    setParams({
      sortField: sorter.field as Key,
      sortDirection: getSortOrder(sorter.order).toLowerCase(),
    });
  };

  const fetchDatasheet = async () => {
    try {
      setIsLoading(true);
      setDatasheetData([
        {
          fileName: 'Datasheet_MEMSO8GBIV_BRAdata.pdf',
          partNumber: 'KC.12N01.U35',
          odm: 'Compal TW',
          supplier: 'Adata',
          dateHour: '00/00/0000 - 09:57 AM',
          resposibleSupplier: 'José Carlos',
          url: 'http://...',
        },
      ]);
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message || t('toast.errorOnList') });
    } finally {
      setIsLoading(false);
    }
  };

  const columns: ColumnsType<IDatasheet> = [
    {
      title: t('pages.viewQualification.datasheet.columnsTable.partNumber'),
      dataIndex: 'fileName',
      key: 'fileName',
      align: 'left',
      width: '20rem',
      render: (_, record) => {
        return (
          <S.FileName onClick={() => window.open(record.url, '_blank')}>
            <PaperClipOutlined />
            <span>{record.fileName}</span>
          </S.FileName>
        );
      },
    },
    {
      title: t('pages.viewQualification.datasheet.columnsTable.partNumber'),
      dataIndex: 'partNumber',
      key: 'partNumber',
      align: 'left',
      width: '10rem',
    },
    {
      title: t('pages.viewQualification.datasheet.columnsTable.odm'),
      dataIndex: 'odm',
      key: 'odm',
      align: 'left',
      width: '10rem',
    },
    {
      title: t('pages.viewQualification.datasheet.columnsTable.supplier'),
      dataIndex: 'supplier',
      key: 'supplier',
      align: 'left',
      width: '11.5rem',
    },
    {
      title: t('pages.viewQualification.datasheet.columnsTable.dateHour'),
      dataIndex: 'dateHour',
      key: 'dateHour',
      align: 'left',
      width: '13rem',
      sorter: true,
    },
    {
      title: t('pages.viewQualification.datasheet.columnsTable.responsibleSupplier'),
      dataIndex: 'resposibleSupplier',
      key: 'resposibleSupplier',
      align: 'left',
      width: '12rem',
    },
    {
      title: t('pages.viewQualification.datasheet.columnsTable.actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: '5rem',
      render: (_, record) => {
        const actions = [
          {
            key: '1',
            label: (
              <Button type="link" disabled>
                <SyncOutlined />
                {t('pages.viewQualification.datasheet.columnsTable.requestUpdate')}
              </Button>
            ),
          },
        ];
        return <TableAction dropdownItems={actions} placement="bottom" />;
      },
    },
  ];

  useEffect(() => {
    fetchDatasheet();
  }, []);

  return {
    columns,
    handleChangeTable,
    isLoading,
    datasheetData,
  };
}
