import { useEffect, useState } from 'react';
import { ConfettiContainer, ConfettiPiece } from './styles';
import { IConfettiPiece } from './types';

export function Confetti() {
  const [confettis, setConfettis] = useState<IConfettiPiece[]>([]);

  const confettiColors = ['#5EA320', '#80C343', '#AFF66F', '#5F5F5F', '#A9A9A9', '#EDFCD9'];
  const COUNT_CONFETTI = 80;

  const generateConfettis = () => {
    const newConfettis = Array.from({ length: COUNT_CONFETTI }, (_, i) => ({
      id: i,
      x: Math.random() * window.innerWidth,
      y: Math.random() * window.innerHeight,
      rotation: Math.random() * 360,
      size: Math.floor(Math.random() * (20 - 5 + 1)) + 5,
      color: confettiColors[Math.floor(Math.random() * confettiColors.length)],
      delay: Math.random() * 3,
      radius: Math.floor(Math.random() * 101),
    }));
    setConfettis(newConfettis);
  };

  useEffect(() => {
    generateConfettis();
  }, []);

  return (
    <ConfettiContainer>
      {confettis.map((confetti) => {
        return (
          <ConfettiPiece
            key={confetti.id}
            x={confetti.x}
            color={confetti.color}
            radius={confetti.radius}
            rotation={confetti.rotation}
            size={confetti.size}
            y={confetti.y}
            delay={confetti.delay}
          />
        );
      })}
    </ConfettiContainer>
  );
}
