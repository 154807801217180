import { InfoCircleFilled } from '@ant-design/icons';
import { Col, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { TagStatusIndication } from '../../../../components/Qualification/TagStatusIndication';
import { formatNumberToOrdinal } from '../../../../helpers/FormatValues';
import { IQualificationRegisterInformation } from '../../types';
import * as S from './styles';

export function QualificationRegisterInformations(data: IQualificationRegisterInformation) {
  const { t } = useTranslation();
  return (
    <S.Container>
      <S.Header>
        <S.Padding>{t('pages.viewQualification.qualificationRegisterInformation.title')}</S.Padding>
      </S.Header>
      <S.Content>
        <S.WaitingContent>
          <InfoCircleFilled />
          {t('pages.viewQualification.qualificationRegisterInformation.waitingSupplier')}
        </S.WaitingContent>
        <Flex gap={16} style={{ width: '100%' }}>
          <S.Card style={{ width: '19rem' }}>
            <span>{t('pages.viewQualification.qualificationRegisterInformation.processType')}</span>
            <span className="value">{data.processType || '-'}</span>
          </S.Card>
          <S.Card style={{ width: '19rem' }}>
            <span>{t('pages.viewQualification.qualificationRegisterInformation.supplier')}</span>
            <span className="value">{data.supplier || '-'}</span>
          </S.Card>
          <S.Card style={{ width: '19rem' }}>
            <span>
              {t(
                'pages.viewQualification.qualificationRegisterInformation.submissionRankingPosition'
              )}
            </span>
            <span className="value">
              {data.rankingPosition ? formatNumberToOrdinal(data.rankingPosition) : '-'}
            </span>
          </S.Card>
          <S.Card style={{ width: '19rem' }}>
            <span>
              {t('pages.viewQualification.qualificationRegisterInformation.sampleQtyTotal')}
            </span>
            <span className="value">{data.sampleQtyTotal || '-'}</span>
          </S.Card>
        </Flex>
        <Col span={24}>
          <S.InformationContent gutter={16}>
            <Col span={6}>
              <Flex vertical gap={12}>
                <S.Label>
                  {t('pages.viewQualification.qualificationRegisterInformation.createdBy')}
                </S.Label>
                <S.Value isBlue>{data.createdBy || '-'}</S.Value>
              </Flex>
            </Col>
            <Col span={6}>
              <Flex vertical gap={12}>
                <S.Label>
                  {t('pages.viewQualification.qualificationRegisterInformation.createdOn')}
                </S.Label>
                <S.Value>{data.createdOn || '-'}</S.Value>
              </Flex>
            </Col>
            <Col span={6}>
              <Flex vertical gap={12}>
                <S.Label>
                  {t('pages.viewQualification.qualificationRegisterInformation.idQualification')}
                </S.Label>
                <S.Value>{data.idQualification || '-'}</S.Value>
              </Flex>
            </Col>
            <Col span={6}>
              <Flex vertical gap={12}>
                <S.Label>
                  {t('pages.viewQualification.qualificationRegisterInformation.status')}
                </S.Label>
                {data.status ? <TagStatusIndication status={data.status} /> : '-'}
              </Flex>
            </Col>
          </S.InformationContent>

          <S.InformationContent gutter={16}>
            <Col span={6}>
              <Flex vertical gap={12}>
                <S.Label>
                  {t('pages.viewQualification.qualificationRegisterInformation.sampleRequest')}
                </S.Label>
                <S.Value>13/12/2024</S.Value>
              </Flex>
            </Col>
            <Col span={6}>
              <Flex vertical gap={12}>
                <S.Label>
                  {t('pages.viewQualification.qualificationRegisterInformation.odm1')}
                </S.Label>
                <S.Value>{data.odmDetails ? data.odmDetails[0].odm : '-'}</S.Value>
              </Flex>
            </Col>
            <Col span={6}>
              <Flex vertical gap={12}>
                <S.Label>
                  {t('pages.viewQualification.qualificationRegisterInformation.odm2')}
                </S.Label>
                <S.Value>{data.odmDetails ? data?.odmDetails[1]?.odm : '-'}</S.Value>
              </Flex>
            </Col>
            <Col span={6}>
              <Flex vertical gap={12}>
                <S.Label>
                  {t('pages.viewQualification.qualificationRegisterInformation.cm')}
                </S.Label>
                <S.Value>{data.cm || '-'}</S.Value>
              </Flex>
            </Col>
          </S.InformationContent>
        </Col>
        <S.Divider />
        <S.InformationContent gutter={16}>
          <Col span={6}>
            <Flex vertical gap={12}>
              <S.Label>
                {t('pages.viewQualification.qualificationRegisterInformation.product')}
              </S.Label>
              <S.Value>{data.product || '-'}</S.Value>
            </Flex>
          </Col>
          <Col span={6}>
            <Flex vertical gap={12}>
              <S.Label>
                {t('pages.viewQualification.qualificationRegisterInformation.project')}
              </S.Label>
              <S.Value>{data.project || '-'}</S.Value>
            </Flex>
          </Col>
          <Col span={6}>
            <Flex vertical gap={12}>
              <S.Label>
                {t('pages.viewQualification.qualificationRegisterInformation.category')}
              </S.Label>
              <S.Value>{data.category || '-'}</S.Value>
            </Flex>
          </Col>
        </S.InformationContent>
      </S.Content>
    </S.Container>
  );
}
