import styled from 'styled-components';

interface LegendIndicatorColor {
  color: string;
}

export const LegendContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  justify-content: end;
  margin: 2.813rem 1.875rem 0 0;
`;

export const IndicatorLegend = styled.div<LegendIndicatorColor>`
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

export const TextLegend = styled.span`
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
`;

export const TooltipContainer = styled.div`
  min-width: 12rem;
  border: 0.063rem solid ${({ theme }) => theme.colorsDesignSystem.border};
  border-radius: 0.313rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.backgroundTooltipChart};
  box-shadow: 0 0.25rem 0.625rem ${({ theme }) => theme.colorsDesignSystem.boxShadowCard};
  padding: 0.8rem 0.5rem 1.1rem;
  :hover {
    background-color: none;
  }
`;

export const TooltipMonth = styled.span`
  font-size: 0.8rem;
  font-weight: 400;
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.8rem;
  gap: 0.8rem;
`;

export const TooltipLegend = styled.span`
  font-size: 0.8rem;
  font-weight: 400;
`;

export const TooltipLegendPriceBook = styled.span`
  text-align: center;
  font-size: 2.5rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.chartColors.green};
  padding: 0;
  margin: 0;
`;
export const IndicatorLegendPriceBook = styled.div`
  height: 0.2rem;
  width: 0.75rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.chartColors.green};
`;

export const IndicatorLegendAverage = styled.div`
  height: 0.2rem;
  width: 0.75rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.chartColors.gray};
`;

export const IndicatorLegendSupplierInvoice = styled.div`
  height: 0.2rem;
  width: 0.75rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.chartColors.orange};
`;

export const TooltipValue = styled.span`
  font-size: 0.8rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;
