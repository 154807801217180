import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { QualificationTagStatus } from '../../helpers/enums';
import { IQualificationData } from './types';

export function useViewQualification() {
  const [qualificationData, setQualificationData] = useState<IQualificationData>();

  const fetchQualificationData = () => {
    const mockData: IQualificationData = {
      partNumber: 'KC.12N01.U35',
      qStatus: 'Nq',
      cm: 'Foxconn',
      category: 'MEM',
      specification: 'SO8GBIV_BR',
      project: 'Jade_TL',
      supplier: 'A-DATA',
      description: 'CPU(BGA) Intel Core i5 i5-1235U BGA 1.3 GHz 15W Alder Lake 282P 99AW6P 80EU',
      costUsd: null,
      processType: 'New Component',
      sampleQtyTotal: 20,
      createdBy: 'Davi Vital',
      createdOn: dayjs().toString(),
      idQualification: 'Q3_2024_1',
      status: QualificationTagStatus['Sent Supplier'],
      sampleRequest: dayjs().toString(),
      odmDetails: [
        {
          odm: 'Compal TW',
          sampleQuantity: 14,
          companyContactPerson: 'Nome do contado ODM',
          email: 'contatoODM@email.com',
          mobileNumber: '+00 000 0 0000-0000',
          phoneNumber: '+00 000 0 0000-0000',
          zipCode: '00000-000',
          country: 'Taiwan',
          address: '1234 Ipsum Street, Suite 567, Dolor City, Sit Amet Region',
          additionalInformation:
            '1- Lorem ipsum dolor sit amet, consectetur adipiscing elit.          2- Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 3- Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.4- Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.',
        },
        {
          odm: 'Compal C',
          sampleQuantity: 14,
          companyContactPerson: 'Nome do contado ODM',
          email: 'contatoODM@email.com',
          mobileNumber: '+00 000 0 0000-0000',
          phoneNumber: '+00 000 0 0000-0000',
          zipCode: '00000-000',
          country: 'China',
          address: '1234 Ipsum Street, Suite 567, Dolor City, Sit Amet Region',
          additionalInformation:
            '1- Lorem ipsum dolor sit amet, consectetur adipiscing elit.          2- Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 3- Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.4- Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.',
        },
      ],
      product: 'Notebook',
    };

    setQualificationData(mockData);
  };

  useEffect(() => {
    fetchQualificationData();
  }, []);

  return {
    qualificationData,
  };
}
