import { Flex } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useTranslation } from 'react-i18next';
import { handleFormatValueFields } from '../../../../helpers/nUtils';
import theme from '../../../../styles/theme';
import * as S from './styles';
import { CustomTooltipProps } from './types';

dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  monthsShort: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
});

export function useFiananciaMetricsCard() {
  const { t } = useTranslation();

  const handleDate = (tickFormater: string, format: 'MMM' | 'MMMM', isUpperCase: boolean) => {
    if (tickFormater) {
      return isUpperCase
        ? dayjs(tickFormater, 'MMM').locale(t('common.dayjs')).format(format).toUpperCase()
        : dayjs(tickFormater, 'MMM').locale(t('common.dayjs')).format(format);
    }
    return null;
  };

  const CustomTooltip = ({ active, payload, label }: CustomTooltipProps) => {
    if (active && payload) {
      return (
        <S.TooltipContainer>
          <S.TooltipMonth>{label ? handleDate(label, 'MMMM', false) : null}</S.TooltipMonth>
          <S.TooltipContent>
            <Flex justify="space-between">
              <Flex align="center" gap={5}>
                <S.IndicatorLegendPriceBook />
                <S.TooltipLegend>
                  {t('pages.financePage.financialMetrics.graphicLabels.priceBook')}
                </S.TooltipLegend>
              </Flex>
              <S.TooltipValue>
                {payload.length > 0
                  ? handleFormatValueFields({ number: payload[0].payload.priceBook })
                  : '-'}
              </S.TooltipValue>
            </Flex>
            <Flex justify="space-between">
              <Flex align="center" gap={5}>
                <S.IndicatorLegendAverage />
                <S.TooltipLegend>
                  {t('pages.financePage.financialMetrics.graphicLabels.average')}
                </S.TooltipLegend>
              </Flex>
              <S.TooltipValue>
                {payload.length > 0
                  ? handleFormatValueFields({ number: payload[0].payload.average })
                  : '-'}
              </S.TooltipValue>
            </Flex>
            <Flex justify="space-between">
              <Flex align="center" gap={5}>
                <S.IndicatorLegendSupplierInvoice />
                <S.TooltipLegend>
                  {t('pages.financePage.financialMetrics.graphicLabels.supplierInvoice')}
                </S.TooltipLegend>
              </Flex>
              <S.TooltipValue>
                {payload.length > 0
                  ? handleFormatValueFields({ number: payload[0].payload.supplierInvoice })
                  : '-'}
              </S.TooltipValue>
            </Flex>
          </S.TooltipContent>
        </S.TooltipContainer>
      );
    }
    return null;
  };

  const handleRenderLegend = () => {
    return (
      <div>
        <S.LegendContainer>
          <S.IndicatorLegend color={theme.colorsDesignSystem.chartColors.green} />
          <S.TextLegend>
            {t('pages.financePage.financialMetrics.graphicLabels.priceBook')}
          </S.TextLegend>
          <S.IndicatorLegend color={theme.colorsDesignSystem.chartColors.gray} />
          <S.TextLegend>
            {t('pages.financePage.financialMetrics.graphicLabels.average')}
          </S.TextLegend>
          <S.IndicatorLegend color={theme.colorsDesignSystem.chartColors.orange} />
          <S.TextLegend>
            {t('pages.financePage.financialMetrics.graphicLabels.supplierInvoice')}
          </S.TextLegend>
        </S.LegendContainer>
      </div>
    );
  };

  return {
    handleDate,
    CustomTooltip,
    handleRenderLegend,
  };
}
