import { Flex } from 'antd';
import styled from 'styled-components';
import { TrackingTagEnum } from '../../../helpers/enums';
import styledTheme from '../../../styles/theme';

const FontColor = {
  New: styledTheme.colorsDesignSystem.qualificationTagColors.goldenYellow,
  Updated: styledTheme.colorsDesignSystem.qualificationTagColors.darkGray,
  Delivered: styledTheme.colorsDesignSystem.avocato,
};

export const Tag = styled(Flex)<{ status: TrackingTagEnum }>`
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: ${({ status }) => FontColor[status]};

  .circle {
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
    background-color: ${({ status }) => FontColor[status]};
  }
`;
