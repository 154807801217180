import { Empty } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import ExpandableCard from '../../../../components/Common/ExpandableCard';
import theme from '../../../../styles/theme';
import HeaderCard from '../HeaderCards';
import { FinancialMetricsCardProps } from './types';
import { useFiananciaMetricsCard } from './useFinancialMetrics';

export function FinancialMetricsCard({ componentName, data }: FinancialMetricsCardProps) {
  const { handleDate, CustomTooltip, handleRenderLegend } = useFiananciaMetricsCard();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const { t } = useTranslation();

  return (
    <ExpandableCard
      isOpen
      headerCard={
        <HeaderCard
          title={t('pages.financePage.financialMetrics.title')}
          componentName={componentName}
          forMonth="  For Month (USD) "
          description={data?.description || ''}
        />
      }
      onExpandableContent={() => true}
    >
      {data ? (
        <LineChart
          width={screenWidth - 120}
          height={250}
          data={data?.financialData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            padding={{ left: 45, right: 45 }}
            tickFormatter={(tick) => handleDate(tick, 'MMM', true) || ''}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend align="right" content={handleRenderLegend} />
          <Line
            type="monotone"
            dataKey="priceBook"
            stroke={theme.colorsDesignSystem.chartColors.green}
          />
          <Line
            type="monotone"
            dataKey="average"
            stroke={theme.colorsDesignSystem.chartColors.gray}
          />
          <Line
            type="monotone"
            dataKey="supplierInvoice"
            stroke={theme.colorsDesignSystem.chartColors.orange}
          />
        </LineChart>
      ) : (
        <Empty />
      )}
    </ExpandableCard>
  );
}
