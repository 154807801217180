import { Alert, Flex, Steps } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import CardCountDown from '../../../components/Common/CardCountdown';
import { handleFormatValueFields } from '../../../helpers/nUtils';
import { VerticalCard } from '../components/VerticalCard';
import * as CS from '../styles';
import * as S from './styles';
import { ISummary } from './types';

export function Summary({ steps, summaryInformations, originalBidId, round }: ISummary) {
  const { t } = useTranslation();
  return (
    <Flex vertical gap={24}>
      <S.StepsContent>
        <Steps items={steps} />
      </S.StepsContent>
      <CS.Content>
        <CS.ContentHeader isOpen>{t('pages.viewRfq.summary.title')}</CS.ContentHeader>
        <S.SummaryContent>
          <Flex vertical gap={30}>
            {summaryInformations?.cancelMessage && (
              <Alert
                message={
                  <>
                    <S.AlertCancelLabel>
                      {' '}
                      {t('pages.viewRfq.summary.cancelLabel')}{' '}
                    </S.AlertCancelLabel>
                    <S.AlertCancelMessage>
                      {decodeURI(summaryInformations?.cancelMessage)}
                    </S.AlertCancelMessage>
                  </>
                }
                type="error"
                showIcon
              />
            )}
            <Flex gap={16} justify="space-between">
              {/* /em caso de novo round */}
              {originalBidId && round && (
                <S.SummaryCard>
                  <span>{t('pages.viewRfq.summary.round')}</span>
                  <b>{round}</b>
                </S.SummaryCard>
              )}
              <S.SummaryCard>
                <span>{t('pages.viewRfq.summary.supplierParticiation')}</span>
                <b>{summaryInformations?.suppliers}</b>
              </S.SummaryCard>
              <S.SummaryCard>
                <span>{t('pages.viewRfq.summary.componentsQuoted')}</span>
                <b>{summaryInformations?.components}</b>
              </S.SummaryCard>
              <S.SummaryCard>
                {summaryInformations?.bidPeriod ? (
                  <CardCountDown
                    endDate={summaryInformations?.bidPeriod.end || '-'}
                    showEndDate={false}
                  />
                ) : (
                  <>
                    <span>{t('pages.viewRfq.summary.timeToCloseTheBid')}</span>
                    <b>-</b>
                  </>
                )}
              </S.SummaryCard>
            </Flex>
            <Flex justify="space-between">
              <Flex vertical gap={24}>
                <VerticalCard
                  title={t('pages.viewRfq.summary.createdBy')}
                  value={summaryInformations?.createdBy || '-'}
                  valueBlue
                />
                <VerticalCard
                  title={t('pages.viewRfq.summary.nameBid')}
                  value={summaryInformations?.name || '-'}
                />
                <VerticalCard
                  title={t('pages.viewRfq.summary.priceInterval')}
                  value={
                    summaryInformations?.priceInterval
                      ? handleFormatValueFields({ number: summaryInformations.priceInterval })
                      : '-'
                  }
                />
              </Flex>
              <Flex vertical gap={24}>
                <VerticalCard
                  title={t('pages.viewRfq.summary.createdOn')}
                  value={
                    summaryInformations?.createdOn
                      ? dayjs(summaryInformations.createdOn).format('YYYY-MM-DD HH:mm')
                      : '-'
                  }
                />
                <VerticalCard
                  title={t('pages.viewRfq.summary.description')}
                  value={summaryInformations?.description || '-'}
                />
                <VerticalCard
                  title={t('pages.viewRfq.summary.attemptLimit')}
                  value={summaryInformations?.attemptLimit || '-'}
                />
              </Flex>
              <Flex vertical gap={12} justify="center">
                <CS.Title>{t('pages.viewRfq.summary.bidPeriod')}</CS.Title>
                <CS.Value>
                  {summaryInformations?.bidPeriod
                    ? `${dayjs(summaryInformations.bidPeriod.start).format(
                        'YYYY-MM-DD HH:mm'
                      )} | ${dayjs(summaryInformations.bidPeriod.end).format('YYYY-MM-DD HH:mm')}`
                    : '-'}{' '}
                </CS.Value>
              </Flex>
            </Flex>
          </Flex>
        </S.SummaryContent>
      </CS.Content>
    </Flex>
  );
}
