import { InfoCircleFilled } from '@ant-design/icons';
import { Flex, Tooltip } from 'antd';
import { t } from 'i18next';
import { handleFormatValueFields, handleVerifyValueIsPositive } from '../../../helpers/nUtils';
import theme from '../../../styles/theme';
import * as S from './styles';
import { CardPriceProps } from './types';
import { useCardPrices } from './useCardPrices';

export default function CardPrices({
  type,
  value,
  impactSavingEstimatePercentage,
  impactSavingEstimateTotal,
  outdated,
}: CardPriceProps) {
  const { handleSelectIcon, handleSelectTitle } = useCardPrices();

  return (
    <S.Container>
      <S.Circle>{handleSelectIcon(type)}</S.Circle>
      <S.ContentCard>
        <Flex gap={12} align="center" justify="center">
          <S.Label>{handleSelectTitle(type)}</S.Label>
          {outdated && (
            <Tooltip
              placement="rightTop"
              title={t('pages.awardSimulation.tooltipMessages.outdated')}
            >
              <S.StyledInfoCircle />
            </Tooltip>
          )}
        </Flex>
        {type === 'ESTIMATE' ? (
          <S.ContainerImpactLabels>
            <S.Label>
              {impactSavingEstimatePercentage
                ? `${handleFormatValueFields({
                    number: impactSavingEstimatePercentage,
                    notation: 'compact',
                  })}%`
                : '-'}
            </S.Label>
            <S.Label>|</S.Label>
            <S.ImpactLabel
              positive={
                impactSavingEstimateTotal
                  ? handleVerifyValueIsPositive(impactSavingEstimateTotal)
                  : true
              }
            >
              {impactSavingEstimateTotal ? (
                <Tooltip
                  title={handleFormatValueFields({
                    number: impactSavingEstimateTotal,
                  })}
                >
                  $
                  {handleFormatValueFields({
                    number: impactSavingEstimateTotal,
                    notation: 'compact',
                  })}
                </Tooltip>
              ) : (
                '-'
              )}
            </S.ImpactLabel>
          </S.ContainerImpactLabels>
        ) : (
          <p className="title">
            {value ? (
              <Tooltip
                title={handleFormatValueFields({
                  number: value,
                })}
              >
                $
                {handleFormatValueFields({
                  number: value,
                  notation: 'compact',
                })}
              </Tooltip>
            ) : (
              '-'
            )}
          </p>
        )}
      </S.ContentCard>
    </S.Container>
  );
}
