import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';

export function NewRoundModal() {
  const { t } = useTranslation();
  return (
    <>
      <Divider orientation="left" orientationMargin="0">
        {t('components.modals.newRound.subtitle')}
      </Divider>
      <span>{t('components.modals.newRound.description')}</span>
    </>
  );
}
