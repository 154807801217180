import { Row } from 'antd';
import styled from 'styled-components';

export const StyledForm = styled.div<{
  removePadding?: boolean;
  removeBorder?: boolean;
}>`
  background-color: ${({ theme }) => theme.colorsDesignSystem.tag_background};
  padding: ${({ removePadding }) => (removePadding ? '0' : '2rem 5.3rem')};
  border-radius: 0.378rem;
  border: ${({ removeBorder, theme }) =>
    removeBorder ? 'none' : `1px solid ${theme.colorsDesignSystem.tertiaryBorder}`};
  overflow-y: auto;

  .ant-picker .ant-picker-suffix {
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
  }
`;

export const ContentForm = styled.div`
  padding: 1rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-height: calc(100vh - 20rem);
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 1.125rem;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.625rem 0.625rem ${({ theme }) => theme.colorsDesignSystem.grayBorder};
    border: solid 0.375rem transparent;
    border-radius: 624rem;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
    box-shadow: inset 0 0 0.625rem 0.625rem ${({ theme }) => theme.colorsDesignSystem.placeHolder};
    border: solid 0.375rem transparent;
    -webkit-border-radius: 624rem;
    border-radius: 624rem;
  }
`;

export const StepTitle = styled(Row)`
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.border};
  padding: 1rem 2.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const StepFooter = styled(Row)`
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colorsDesignSystem.border};
  padding: 1rem 2.5rem;
`;
