import { useState } from 'react';
import { CardSuppliers } from '../../../components/Common/CardBidSuplliers';
import { SupplierTotalAwards } from './types';

export function useModalTotalAwards() {
  const [openCard, setOpenCard] = useState(true);

  const handleRenderCardsBidSupplier = (suppliers: Array<SupplierTotalAwards>) => {
    return suppliers.map((supplier) => (
      <CardSuppliers
        key={supplier.supplier}
        demand={supplier.demand ? String(supplier.demand) : ''}
        impact={supplier.impactSavingPercentage ? String(supplier.impactSavingPercentage) : ''}
        saving={supplier.impactSaving ? String(supplier.impactSaving) : ''}
        supplier={supplier.supplier}
        totalWin={supplier.totalWin ? String(supplier.totalWin) : ''}
      />
    ));
  };

  return {
    openCard,
    setOpenCard,

    handleRenderCardsBidSupplier,
  };
}
