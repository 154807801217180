import { Divider, Flex } from 'antd';
import { t } from 'i18next';
import * as S from './styles';
import StyledButton from '../../../components/Common/StyledButton';
import { AwardModalProps } from './types';
import { useModalSendAward } from './useModalSendAward';

export default function ModalSendAward({ onClose, handleSaveAward }: AwardModalProps) {
  const { message, setMessage, handleClearMessage, handleValidateMessage } = useModalSendAward();
  return (
    <S.ContainerModal>
      <Flex>
        <Divider orientationMargin={0} orientation="left">
          <S.LabelTitleModal>
            {t('pages.awardSimulation.modalSendAwards.subTitle')}
          </S.LabelTitleModal>
        </Divider>
      </Flex>
      <Flex vertical gap={4}>
        <S.LabelsTexts>{t('pages.awardSimulation.modalSendAwards.firstText')}</S.LabelsTexts>
        <Flex vertical>
          <p>
            <S.LabelsTexts isBold>
              {t('pages.awardSimulation.modalSendAwards.secondText')}{' '}
            </S.LabelsTexts>
            <S.LabelsTexts> {t('pages.awardSimulation.modalSendAwards.thirdText')}</S.LabelsTexts>
          </p>
          <S.LabelsTexts className="small">
            {t('pages.awardSimulation.modalSendAwards.fourthText')}
          </S.LabelsTexts>
        </Flex>
      </Flex>
      <S.StyledTextArea
        autoSize={{ minRows: 4 }}
        showCount
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
        maxLength={400}
        placeholder={t('pages.awardSimulation.modalSendAwards.placeholder')}
      />
      <S.FooterModal>
        <StyledButton
          variant="secondary"
          onClick={() => {
            onClose(false);
          }}
        >
          {t('pages.awardSimulation.modalSendAwards.buttons.cancel')}
        </StyledButton>
        <StyledButton
          disabled={handleValidateMessage()}
          onClick={() => {
            onClose(false);
            handleSaveAward(message);
            handleClearMessage();
          }}
          variant="primary"
        >
          {t('pages.awardSimulation.labels.send')}
        </StyledButton>
      </S.FooterModal>
    </S.ContainerModal>
  );
}
