import { FormOutlined } from '@ant-design/icons';
import * as S from './styles';
import { IndicationTagRfqProps } from './types';
import useIndicationTagRqf from './useIndicationTagRfq';

export default function IndicationTagRfq({ type, text }: IndicationTagRfqProps) {
  const { handleSelectThemeIndicationTagByType } = useIndicationTagRqf();

  const theme = handleSelectThemeIndicationTagByType(type);

  return (
    <S.StyledTagRfq
      type={type}
      fontColor={theme.fontColor}
      borderColor={theme.borderColor}
      backgroundColor={theme.backgroundColor}
    >
      {type === 'DRAFT' && <FormOutlined />}
      <span>{text === 'CANCELED' ? 'CANCEL' : text}</span>
    </S.StyledTagRfq>
  );
}
