import { Flex } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};

  flex: 1;
`;

export const TitleContainer = styled.div`
  padding: 1.5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  b {
    font-size: 1.25rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }
  span {
    font-size: 1.25rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
  }
`;

export const Content = styled(Flex)`
  justify-content: center;
  padding: 1.5rem;
  flex-wrap: wrap;
  gap: 1.5rem;
`;

export const GraphContent = styled(Flex)`
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.border};
  border-radius: 0.75rem;
  padding: 2rem 0rem;
  width: 100%;
  min-width: 23.75rem;
  flex: 1;
`;

export const Supplier = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  margin-left: 1rem;
`;

export const Percentage = styled.span<{ value: number }>`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme, value }) =>
    value > 0
      ? theme.colorsDesignSystem.rfqIndicationTagColors.redFont
      : theme.colorsDesignSystem.rfqIndicationTagColors.greenFont};
  margin-right: 1.75rem;
`;
