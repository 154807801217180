import { Flex } from 'antd';
import styled from 'styled-components';

export const FiltersContainer = styled(Flex)`
  flex-wrap: wrap;
  gap: 0.875rem;
  align-items: flex-end;
  margin-bottom: 1rem;
  .searchIcon {
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
    font-size: 0.785rem;
  }
`;

export const TagsContainer = styled(Flex)`
  flex-wrap: wrap;
  gap: 0.375rem;
  align-items: center;
  margin-bottom: 1rem;
`;

export const PartNumber = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
`;
