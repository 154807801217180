import { Flex } from 'antd';
import styled from 'styled-components';
import StyledButton from '../../../../components/Common/StyledButton';

export const Container = styled(Flex)`
  flex-direction: column;
  padding: 1rem 1.5rem;
  border-radius: 0.75rem;
  gap: 1rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  .uploadIcon {
    color: ${({ theme }) => theme.colors.input_labels};
    font-size: 1rem;
  }
  .exclamationFile {
    color: ${({ theme }) => theme.colorsDesignSystem.tagColorYellow};
    font-size: 1rem;
  }
  img {
    width: 1rem;
    height: 1rem;
  }
`;

export const Title = styled.h1<{ hasFiles?: boolean }>`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  margin-bottom: 0;
  color: ${({ hasFiles, theme }) =>
    hasFiles ? theme.colorsDesignSystem.tabBarInactiveColor : theme.colors.input_labels};
  cursor: ${({ hasFiles }) => hasFiles && 'pointer'};
`;

export const UploadButton = styled(StyledButton)`
  width: 6.25rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  color: ${({ theme }) => theme.colorsDesignSystem.tabBarInactiveColor};
`;
