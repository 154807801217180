import styled, { keyframes } from 'styled-components';
import { IConfettiPiece } from './types';

const fallAnimation = keyframes`
   0% {
    transform: translateY(0) rotate(0deg);
  }
  100% {
    transform: translateY(110vh) rotate(720deg);
  }
`;

export const ConfettiPiece = styled.div<Omit<IConfettiPiece, 'id'>>`
  position: absolute;
  transform-origin: center bottom;
  top: -20px;
  left: ${({ x }) => x}px;
  transform: ${({ rotation }) => `rotate(${rotation})deg`};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background: ${({ color }) => color};
  animation: ${fallAnimation} 5s ease-out ${({ delay }) => delay}s forwards infinite;
  transform-style: preserve-3d;
  border-radius: ${({ radius }) => radius}%;
`;

export const ConfettiContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  overflow: hidden;
  z-index: 1001;
`;
