import { Divider as AntDivider, Flex } from 'antd';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  25% { opacity: 0}
	50% { opacity: .1; } 
	100% { opacity: 1; }
`;

export const SupplierResponseCard = styled.div<{ changed: boolean }>`
  min-width: 19rem;
  display: flex;
  height: auto;
  flex-direction: column;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
  animation: ${({ changed }) => (changed ? rotate : 'none')} 0.3s linear;
`;

export const Padding = styled.div`
  padding: 1.5rem;
`;

export const Classification = styled.div`
  display: flex;
  align-items: center;
  min-width: 4rem;
  max-width: max-content;
  max-height: 2rem;
  justify-content: center;
  padding: 0.5rem 1rem;
  border-radius: 3.125rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.25rem;
`;

export const Title = styled.span`
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const VariantContent = styled(Flex)`
  align-items: center;
  gap: 0.5rem;
  height: 1.5rem;
`;

export const VariancePercentage = styled.span`
  font-size: 0.78rem;
  font-weight: 700;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const VarianceValue = styled.span`
  font-size: 0.9rem;
  font-weight: 700;
  line-height: 1.4rem;
  color: ${({ theme }) => theme.colorsDesignSystem.red_secondary};
`;

export const PartNumber = styled.span<{ qStatus: string }>`
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  color: ${({ theme, qStatus }) =>
    qStatus === 'Qg' ? theme.colorsDesignSystem.tagColorYellow : theme.colorsDesignSystem.text};
`;

export const Divider = styled(AntDivider)`
  margin: 1rem 0;
`;

export const Span = styled.span<{ isBold?: boolean; isSemiBold?: boolean }>`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.input_labels};
  font-weight: ${({ isBold, isSemiBold }) => (isBold ? 700 : isSemiBold ? 600 : 400)};
  line-height: 0.875rem;
`;

export const Value = styled.span<{
  isBold?: boolean;
  color?: 'blue' | 'green' | 'gray' | 'normal' | 'red';
}>`
  font-size: 0.875rem;
  color: ${({ theme, color }) =>
    color === 'gray'
      ? theme.colorsDesignSystem.text
      : color === 'green'
      ? theme.colorsDesignSystem.rfqIndicationTagColors.greenFont
      : color === 'blue'
      ? theme.colorsDesignSystem.blue
      : color === 'red'
      ? theme.colorsDesignSystem.red_secondary
      : theme.colors.input_labels};
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  line-height: 1.375rem;
`;

export const EmptyRow = styled.div`
  height: 1.375rem;
`;

export const GapGross = styled.span<{ value: number }>`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${({ value, theme }) =>
    value > 0
      ? theme.colorsDesignSystem.red_secondary
      : value < 0
      ? theme.colorsDesignSystem.rfqIndicationTagColors.greenFont
      : theme.colorsDesignSystem.text};
`;

export const Percentage = styled.span<{ isBold?: boolean }>`
  line-height: 1.375rem;
  font-size: 0.75rem;
  font-weight: ${({ isBold }) => (isBold ? 700 : 400)};
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;

export const ImpactSavingDiv = styled.div`
  padding: 1.25rem 1.5rem;
  border-top: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
`;

export const ImpactValue = styled.span<{ value: number }>`
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.375rem;
  color: ${({ value, theme }) =>
    value > 0
      ? theme.colorsDesignSystem.red_secondary
      : value < 0
      ? theme.colorsDesignSystem.rfqIndicationTagColors.greenFont
      : theme.colorsDesignSystem.text};
`;
