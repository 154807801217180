/* eslint-disable no-undefined */
import { ColumnsType } from 'antd/es/table';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/es/table/interface';
import { Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { oneAlert } from '../../../../helpers/nUtils';
import { getSortOrder } from '../../../../helpers/utils';

import { TrackingTag } from '../../../../components/Qualification/TrackingTag';
import { TrackingTagEnum } from '../../../../helpers/enums';
import * as S from './styles';
import { IParams, ISampleTrackingNumber } from './types';

export function useSampleTrackingNumber() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [trackingNumberData, setTrackingNumberData] = useState<ISampleTrackingNumber[]>([]);
  const [isTrackingNumberModalOpen, setIsTrackingNumberModalOpen] = useState<{
    isModalOpen: boolean;
    trackingNumberData: ISampleTrackingNumber | undefined;
  }>({
    isModalOpen: false,
    trackingNumberData: undefined,
  });

  const initialParams: IParams = {
    sortField: 'dateHour' as Key,
    sortDirection: 'asc',
  };
  const [params, setParams] = useState<IParams>(initialParams);

  const handleChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ISampleTrackingNumber[]>
  ) => {
    setParams({
      sortField: sorter.field as Key,
      sortDirection: getSortOrder(sorter.order).toLowerCase(),
    });
  };

  const fetchTrackingNumber = async () => {
    try {
      setIsLoading(true);
      setTrackingNumberData([
        {
          trackingNumber: 'N00N000000NNN',
          freightForwarder: 'Fedex',
          odm: 'Compal TW',
          supplier: 'Adata',
          dateHour: '00/00/0000 - 09:57 AM',
          resposibleSupplier: 'José Carlos',
          status: TrackingTagEnum.New,
        },
      ]);
    } catch (error: any) {
      oneAlert({ type: 'error', message: error.message || t('toast.errorOnList') });
    } finally {
      setIsLoading(false);
    }
  };

  const columns: ColumnsType<ISampleTrackingNumber> = [
    {
      title: t('pages.viewQualification.sampleTrackingNumber.columnsTable.trackingNumber'),
      dataIndex: 'trackingNumber',
      key: 'trackingNumber',
      align: 'left',
      width: '15rem',
      render: (_, record) => {
        return (
          <S.TrackingNumber
            onClick={() =>
              setIsTrackingNumberModalOpen({
                isModalOpen: true,
                trackingNumberData: record,
              })
            }
          >
            {record.trackingNumber}
          </S.TrackingNumber>
        );
      },
    },
    {
      title: t('pages.viewQualification.sampleTrackingNumber.columnsTable.freightForwarder'),
      dataIndex: 'freightForwarder',
      key: 'freightForwarder',
      align: 'left',
      width: '10rem',
    },
    {
      title: t('pages.viewQualification.sampleTrackingNumber.columnsTable.odm'),
      dataIndex: 'odm',
      key: 'odm',
      align: 'left',
      width: '10rem',
    },
    {
      title: t('pages.viewQualification.sampleTrackingNumber.columnsTable.supplier'),
      dataIndex: 'supplier',
      key: 'supplier',
      align: 'left',
      width: '11.5rem',
    },
    {
      title: t('pages.viewQualification.sampleTrackingNumber.columnsTable.dateHour'),
      dataIndex: 'dateHour',
      key: 'dateHour',
      align: 'left',
      width: '13rem',
      sorter: true,
    },
    {
      title: t('pages.viewQualification.sampleTrackingNumber.columnsTable.responsibleSupplier'),
      dataIndex: 'resposibleSupplier',
      key: 'resposibleSupplier',
      align: 'left',
      width: '12rem',
    },
    {
      title: t('pages.viewQualification.sampleTrackingNumber.columnsTable.status'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '10rem',
      render: (_, record) => {
        return <TrackingTag status={TrackingTagEnum[record.status]} />;
      },
    },
  ];

  useEffect(() => {
    fetchTrackingNumber();
  }, []);

  return {
    columns,
    handleChangeTable,
    isLoading,
    trackingNumberData,
    isTrackingNumberModalOpen,
    setIsTrackingNumberModalOpen,
  };
}
