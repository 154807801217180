import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import { ComponentSpecifications } from '../../components/Qualification/ComponentSpecifications';
import { QualificationStatus } from '../../components/Qualification/QualificationStatus';
import { Datasheet } from './components/Datasheet';
import { ODMDetails } from './components/ODMDetails';
import { QualificationRegisterInformations } from './components/QualificationRegisterInformations';
import { SampleTrackingNumber } from './components/SampleTrackingNumber';
import * as S from './styles';
import { useViewQualification } from './useViewQualification';

export function ViewQualification() {
  const { t } = useTranslation();
  const history = useHistory();
  const { qualificationData } = useViewQualification();
  return (
    <S.Container>
      <S.BackButton
        type="link"
        icon={<ArrowLeftOutlined />}
        onClick={() => history.push('/qualification')}
      >
        {t('pages.viewQualification.backButton')}
      </S.BackButton>
      <StyledHeader title={t('pages.viewQualification.title')} />
      <QualificationStatus />
      <QualificationRegisterInformations {...qualificationData} />
      <ComponentSpecifications viewAcer {...qualificationData} />
      <Datasheet qualificationId="" />
      <SampleTrackingNumber qualificationId="" />
      <ODMDetails data={qualificationData?.odmDetails} />
    </S.Container>
  );
}
