import styled from 'styled-components';
import StyledButton from '../../components/Common/StyledButton';

export const SpanTile = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  padding-right: 0;
`;

export const BidComponents = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
  margin: 2rem 0.25rem;
`;

export const SupplierSpan = styled.span`
  font-size: 1.25rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const UserContainer = styled.div`
  display: flex;
  padding-left: 0.25rem;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  border-left: 0.125rem solid ${({ theme }) => theme.colorsDesignSystem.pipeSeparatorColor};

  span {
    color: ${({ theme }) => theme.colorsDesignSystem.grayAcer};
    font-size: 1.25rem;
  }
`;
export const BackButton = styled(StyledButton)`
  padding-left: 0;
`;
