import TextArea from 'antd/es/input/TextArea';
import styled from 'styled-components';

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;

  p {
    margin: 0;
  }
`;

export const LabelTitleModal = styled.span`
  font-size: 1.25rem;
  flex: 1;
  font-weight: 600;
  line-height: 1.5rem;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
`;

export const LabelsTexts = styled.span<{ isBold?: boolean }>`
  font-size: 1rem;
  font-weight: ${({ isBold }) => (isBold ? 'bold' : '400')};
  line-height: 1.5rem;
`;

export const StyledTextArea = styled(TextArea)`
  margin-top: 1.12rem;
  resize: none;
`;

export const FooterModal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding-top: 1rem;
  margin-top: 2rem;
  border-top: 1px solid ${({ theme }) => theme.colorsDesignSystem.borderColor};
`;
