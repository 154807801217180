import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CbomLabel = styled.span`
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  font-weight: 700;
  font-size: 1rem;
`;
export const ComponentName = styled.span`
  color: ${({ theme }) => theme.colorsDesignSystem.blue};
  font-weight: 700;
  font-size: 1rem;
`;

export const ForMonth = styled.span`
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  font-weight: 400;
  font-size: 1rem;
`;
export const Description = styled.span`
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  font-weight: 400;
  font-size: 1rem;
`;
export const Divider = styled.span`
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  font-weight: 400;
  font-size: 1rem;
  margin: 0 0.5rem;
`;
