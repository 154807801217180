import { Button, Flex } from 'antd';
import styled from 'styled-components';

export const Container = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

export const BackButton = styled(Button)`
  padding: 0;
`;
