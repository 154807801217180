import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const ContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 20%;
  min-width: 24.438rem; */
  width: 24.438;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border-radius: 0.75rem 0 0 0.75rem;
  padding: 1.875rem;
  border-right: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const TitleLeftCard = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.black85};
`;

export const Labels = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};

  &.withBorder {
    margin-top: 0.5rem;
  }
  &.normal {
    font-size: 1rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }
  &.large {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }

  &.small {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colorsDesignSystem.input_labels};
  }
`;

export const LabelColored = styled.p<{ isPositive?: boolean }>`
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  color: ${({ isPositive, theme }) =>
    isPositive
      ? theme.colorsDesignSystem.red
      : theme.colorsDesignSystem.rfqIndicationTagColors.greenFont};
`;

export const ContainerCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  > :first-child {
    margin-top: 1.5rem;
  }
`;

export const CardLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  height: 4.5rem;
  gap: 0.5rem;
  min-width: 20.687rem;
  padding: 1rem 1.5rem 1rem 1.5rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.header};
`;

export const CardFromTo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  padding: 0.5rem;
  border-radius: 0.45rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.backgroundDestiny};
`;

export const ContainerCardsRight = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.fontColorHeader};
  box-sizing: border-box;
  border: 0.063rem solid;
  border-color: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.borderColor};
  border-radius: 0.375rem;
  margin-top: 1rem;

  span {
    font-size: 1.125rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colorsDesignSystem.text};

    &.colored {
      font-weight: 600;
      color: ${({ theme }) => theme.colorsDesignSystem.blue};
    }

    &.line {
      font-weight: 400;
      color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
    }

    &.subtitleBold {
      font-size: 1.25rem;
      font-weight: bold;
    }

    &.subtitle {
      font-size: 1.25rem;
      font-weight: 300;
    }

    &.smallTitle {
      color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
`;

export const Line = styled.hr`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  border: none;
  height: 1px;
  background-color: ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};

  &.noMargin {
    flex: 1;
  }
`;

export const GridCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-top: 1rem;
  gap: 1.5rem;
`;

export const CardNetPrices = styled.div`
  padding: 1.5rem;
  flex-wrap: wrap;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  height: 6.675rem;
  min-width: 12rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const HeaderExpandableItem = styled.div`
  padding: 1.125rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.backgroundSecondaryColor};
`;

export const ContainerExpandableContent = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

export const ContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  min-height: 80vh;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 4.625rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  gap: 0.5rem;
  padding-inline-end: 2rem;
  border-end-end-radius: 0.75rem;
`;
