import styled from 'styled-components';

export const Container = styled.main`
  background-attachment: ${({ theme }) => theme.colorsDesignSystem.newTablesColors.background};
  min-height: 95vh;
  overflow-x: auto;
  box-sizing: content-box;
  .pageTitle {
    margin-bottom: 1rem;
  }
`;
export const TitleComponentsPrice = styled.span`
  font-size: ${({ theme }) => theme.dimensions.font_title_large};
  color: ${({ theme }) => theme.colorsDesignSystem.fontColor};
`;

export const SearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  .icon {
    color: ${({ theme }) => theme.colorsDesignSystem.primary};
    font-size: 0.875rem;
  }
`;
