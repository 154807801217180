import { Divider as AntDivider } from 'antd';
import styled from 'styled-components';

export const Container = styled.div<{ selected?: boolean }>`
  display: flex;
  width: 19.1rem;
  flex-direction: column;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  min-height: 28.437rem;
  justify-content: space-between;
  border: 1px solid
    ${({ theme, selected }) =>
      selected
        ? theme.colorsDesignSystem.tabBarInactiveColor
        : theme.colorsDesignSystem.tertiaryBorder};

  hr {
    margin-top: 0.5rem;
    border: none;
    height: 0;
    border: 1px dashed ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  }
`;

export const ContentContainer = styled.div`
  width: 18.9rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  border-radius: 0.75rem;
`;

export const Divider = styled(AntDivider)`
  margin: 1rem 0;
`;

export const Labels = styled.span`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};

  &.strong {
    font-size: 1.5rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }

  &.middle {
    font-size: 0.875rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }

  &.small {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  }
  &.smallBold {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    font-weight: bold;
  }

  &.colored {
    font-size: 0.875rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colorsDesignSystem.red};
  }
`;

export const PositionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
  border-radius: 3.125rem;
  padding: 0.3rem 1rem 0.3rem 1rem;

  span {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
  }
`;

export const ContainerNetPrices = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerInsideNetGrossPrices = styled.div<{ hasMargin: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ hasMargin }) => (hasMargin ? '1rem' : 'none')};
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem 1rem 1.5rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.tag_background};
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  border-top: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const ContentContainerEmpty = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.62rem;
  flex: 1;

  img {
    width: 4.9305rem;
    height: 3.08156rem;
  }

  p {
    font-size: 0.87688rem;
    font-style: normal;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text_disabled};
  }
`;

export const ContainerHighLight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const ButtonIcon = styled.button`
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  margin: 0;
`;

export const ContainerRows = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
  justify-content: space-between;
  padding: 0.2rem 0 0.2rem 0;
`;
export const ContainerBaseline = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  justify-content: space-between;
  padding: 0.75rem 0 0 0;
`;

export const ContainerGross = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
`;

export const ContainerGapGross = styled.div`
  display: flex;
  gap: 0.5rem;
  justify-content: end;
  align-items: center;
`;

export const LabelColored = styled.span<{
  color: 'red' | 'blue' | 'green';
  size: 'normal' | 'strong';
}>`
  font-size: ${({ size }) => (size === 'normal' ? '0.875rem' : '1.25rem')};
  font-weight: ${({ color }) => (color === 'blue' ? 'normal' : 'bold')};
  color: ${({ color, theme }) =>
    color === 'red'
      ? theme.colorsDesignSystem.red
      : color === 'blue'
      ? theme.colorsDesignSystem.blue
      : theme.colorsDesignSystem.rfqIndicationTagColors.greenFont};
`;
