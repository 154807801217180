/* eslint-disable no-undefined */
import { TruckOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import StyledModal from '../../../../components/Common/StyledModal';
import { SampleTrackingNumberModal } from '../../../../components/Qualification/SampleTrackingNumberModal';
import SimpleTable from '../../../../components/Tables/SimpleTable';
import * as S from './styles';
import { ISampleTrackingNumberComponent } from './types';
import { useSampleTrackingNumber } from './useSampleTrackingNumber';

export function SampleTrackingNumber({ qualificationId }: ISampleTrackingNumberComponent) {
  const { t } = useTranslation();
  const {
    columns,
    handleChangeTable,
    isLoading,
    isTrackingNumberModalOpen,
    setIsTrackingNumberModalOpen,
    trackingNumberData,
  } = useSampleTrackingNumber();

  return (
    <S.Container>
      <S.Header>
        <S.Padding>
          <TruckOutlined />
          {t('pages.viewQualification.sampleTrackingNumber.title')}
        </S.Padding>
      </S.Header>
      <S.Content>
        {trackingNumberData.length === 0 ? (
          <Empty
            description={t('pages.viewQualification.datasheet.empty')}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        ) : (
          <SimpleTable
            columns={columns}
            dataSource={trackingNumberData}
            rowKey="trackingNumber"
            pagination={false}
            onChange={handleChangeTable}
            isLoading={isLoading}
          />
        )}
      </S.Content>
      {isTrackingNumberModalOpen.isModalOpen && isTrackingNumberModalOpen.trackingNumberData && (
        <StyledModal
          title={t('components.modals.sampleTrackingNumber.title')}
          body={
            <SampleTrackingNumberModal
              trackingNumberData={isTrackingNumberModalOpen?.trackingNumberData}
            />
          }
          footer={null}
          open={isTrackingNumberModalOpen?.isModalOpen}
          centered
          onClose={() =>
            setIsTrackingNumberModalOpen({
              isModalOpen: false,
              trackingNumberData: undefined,
            })
          }
          width="55rem"
          height="43rem"
          noMargin
          noPadding
        />
      )}
    </S.Container>
  );
}
