import { Col, Flex, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button';
import { oneAlert } from '../../../helpers/utils';
import { ValidateMessages } from '../../../helpers/ValidateMessages';
import { RoutesPath } from '../../../routes/routesPath';
import { api } from '../../../services/api';
import * as SC from '../../../styles/common';

export function UserForm({ onClose, data, fetchUsers, ...rest }) {
  const { t } = useTranslation();
  const validateMessages = ValidateMessages();
  validateMessages.pattern = {
    mismatch: `${t('pages.user.fields.passwordMessage')}`,
  };

  const initialData = data ? { ...data } : null;

  if (data?.group?.name) {
    initialData.group = data.group?.name;
  }

  if (data?.enabled) {
    initialData.enabled = data.enabled === 'true';
  }

  const initialValues = initialData || {
    name: '',
    email: '',
    password: '',
    phone: '',
    group: '',
    enabled: true,
  };

  const onFinish = async (values, stateData) => {
    try {
      console.log('stateData=>', stateData);
      if (!data) {
        const formData = {
          ...values,
        };

        const response = await api.post(
          `${import.meta.env.VITE_API_LOGIN_UNICO}${RoutesPath.users}`,
          formData
        );
        if (response.status !== 201) throw Error();
      } else {
        const formData = {
          ...values,
          enabled: stateData.enabled,
        };

        const response = await api.put(
          `${import.meta.env.VITE_API_LOGIN_UNICO}${RoutesPath.users}/${stateData.id}`,
          formData
        );
        if (response.status !== 200) throw Error();
      }

      oneAlert('success', t('toast.successOnSave'));
      onClose();
      fetchUsers();
    } catch (e) {
      oneAlert('error', t('toast.errorOnSave'));
    }
  };

  return (
    <Row style={{ width: '100%' }}>
      <Col span={24} flex={1}>
        <Form
          layout="vertical"
          initialValues={initialValues}
          onFinish={(values) => onFinish(values, data)}
          validateMessages={validateMessages}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="name"
                label={t('pages.user.fields.name')}
                rules={[{ required: true }]}
              >
                <Input placeholder={t('pages.user.fields.name')} maxLength={100} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="email"
                label={t('pages.user.fields.email')}
                rules={[{ required: true }]}
              >
                <Input placeholder={t('pages.user.fields.email')} maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
          {!data && (
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="password"
                  label={t('pages.user.fields.passwordTemp')}
                  rules={[
                    {
                      required: true,
                      pattern: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$',
                    },
                  ]}
                >
                  <Input.Password
                    type="password"
                    placeholder={t('pages.user.fields.passwordTemp')}
                    maxLength={20}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="phone" label={t('pages.user.fields.phone')}>
                <Input placeholder={t('pages.user.fields.phone')} maxLength={20} />
              </Form.Item>
            </Col>
          </Row>

          <SC.HR />

          <Row>
            <Col span={24}>
              <Flex justify="space-between">
                <Button onClick={onClose} danger>
                  {t('common.cancel')}
                </Button>

                <Button type="primary" htmlType="submit" data-cy="save">
                  {t('common.save')}
                </Button>
              </Flex>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}
