import { Divider as AntDivider, Flex, Row } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colorsDesignSystem.white};
  margin-bottom: 1.5rem;
`;

export const Padding = styled.div`
  padding: 1.625rem 2rem;
`;

export const Header = styled(Flex)`
  gap: 0.75rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colorsDesignSystem.text};
  border-bottom: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
`;

export const Content = styled(Flex)`
  padding: 2rem;
  flex-direction: column;
`;

export const WaitingContent = styled(Flex)`
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 1.5rem;
  margin-bottom: 2.5rem;
  border-radius: 0.375rem;
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.blueBorder};
  background-color: ${({ theme }) => theme.colorsDesignSystem.lightBlue};

  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;

  svg {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.blue_secondary};
  }
`;

export const Card = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
  padding: 1rem 1.5rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) =>
    theme.colorsDesignSystem.newTablesColors.colorBackgroundSubItens};
  border: 1px solid ${({ theme }) => theme.colorsDesignSystem.tertiaryBorder};
  gap: 0.5rem;

  span {
    font-size: 0.875rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
    line-height: 1.25rem;
  }
  .value {
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colorsDesignSystem.text};
    line-height: 1.75rem;
  }
`;

export const InformationContent = styled(Row)`
  margin-top: 2.5rem;
`;

export const Label = styled.span`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ theme }) => theme.colorsDesignSystem.inputLabelColor};
`;
export const Value = styled.span<{ isBlue?: boolean }>`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  color: ${({ theme, isBlue }) =>
    isBlue ? theme.colorsDesignSystem.blue : theme.colorsDesignSystem.inputLabelColor};
`;

export const Divider = styled(AntDivider)`
  margin: 0;
  margin-top: 2.5rem;
`;
