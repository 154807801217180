import { RouteConfig } from './types';

export const routesConfig: RouteConfig = {
  routes: [
    {
      route: 'scenarios',
      path: '/scenarios',
      label: 'Scenarios',
    },
    {
      route: 'simulation',
      path: '/simulation',
      label: 'Simulation',
    },
    {
      route: 'demands',
      path: '/demands',
      label: 'Demands',
    },
    {
      route: 'prices',
      path: '/prices',
      label: 'Prices',
    },
    {
      route: 'boms',
      path: '/boms',
      label: 'BOM',
    },
    {
      route: 'avlc',
      path: '/avlc',
      label: 'AVLC',
    },
    {
      route: 'specification-dashboard',
      path: '/dashboard/specifications',
      label: 'Specification Dashboard',
    },
    {
      route: 'sku-dashboard',
      path: '/dashboard/sku',
      label: 'SKU Dashboard',
    },
    {
      route: 'suppliers',
      path: '/suppliers',
      label: 'Suppliers',
    },
    {
      route: 'sku',
      path: '/sku',
      label: 'SKU',
    },
    {
      route: 'components',
      path: '/components',
      label: 'Components',
    },
    {
      route: 'componentsGroup',
      path: '/components-group',
      label: 'Components Grouping',
    },
    {
      route: 'ppb',
      path: '/ppb',
      label: 'PPB',
    },
    {
      route: 'spot',
      path: '/spot',
      label: 'Spot Strategy',
    },
    {
      route: 'reports',
      path: '/reports/scenarios',
      label: 'Reports',
    },
    {
      route: 'users',
      path: '/users',
      label: 'Users',
    },
    {
      route: 'fileerrors',
      path: '/fileerrors',
      label: 'Files Logs',
    },
    {
      route: 'logusers',
      path: '/logusers',
      label: 'Log Users',
    },
    {
      route: 'alerts',
      path: '/alerts',
      label: 'Alerts',
    },
    {
      route: 'dashboard/finance',
      path: '/dashboard/finance',
      label: 'Finance Dashboard',
    },
    {
      route: 'dashboard/procurement',
      path: '/dashboard/procurement',
      label: 'Procurement Dashboard',
    },
    {
      route: 'quotes',
      path: '/quotes',
      label: 'Quotes',
    },
    {
      route: 'average',
      path: '/average',
      label: 'Average',
    },
    {
      route: 'Uploads',
      path: '/Uploads',
      label: 'Uploads',
    },
    {
      route: 'taxes',
      path: '/taxes',
      label: 'Taxes',
    },
    {
      route: 'prices-taxes',
      path: '/prices-taxes',
      label: 'Prices Taxes',
    },
    {
      route: 'new-demand',
      path: '/new-demand',
      label: 'New Demand',
    },
    {
      route: 'finance',
      path: '/finance',
      label: 'Finance',
    },
    {
      route: 'components-prices',
      path: '/components-prices',
      label: 'Components Prices',
    },
    {
      route: 'inventory',
      path: '/inventory',
      label: 'Inventory',
    },
    {
      route: 'share-proportion',
      path: '/share-proportion',
      label: 'Share Proportion',
    },
    {
      route: 'rfq',
      path: '/rfq',
      label: 'RFQ',
    },
    {
      route: 'bidresponse',
      path: '/bid-response',
      label: 'Bid Response',
    },
    {
      route: 'bidsupplierview',
      path: '/bid-supplier-view',
      label: 'Bid Supplier View',
    },
    {
      route: 'award-split',
      path: '/award-split',
      label: 'Award Split',
    },
    {
      route: 'award-details',
      path: '/award-details',
      label: 'Award Details',
    },
    {
      route: 'award-simulation',
      path: '/award-simulation',
      label: 'Award Simulation',
    },
    {
      route: 'award',
      path: '/award-list',
      label: 'Award List',
    },
    {
      route: 'view-qualification',
      path: '/qualificaiton-summary:id',
      label: 'Qualification Summary',
    },
  ],
};
