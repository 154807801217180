import { Flex, Tooltip } from 'antd';
import { t } from 'i18next';
import exclamationCircle from '../../../assets/exclamation-circle.svg';
import {
  handleFormatDate,
  handleFormatValueFields,
  handleVerifyValueIsPositive,
} from '../../../helpers/nUtils';
import * as S from './styles';
import { AwardDistribution } from './types';
import useCardDistribution from './useCardDistribution';

export default function CardDistribution({
  altGroup,
  demand,
  totalNet,
  impactSaving,
  projectList,
  reviewedIn,
  totalProjects,
  impactSavingPercentage,
  taxesEmpty,
  answered,
  bidId,
}: AwardDistribution) {
  const { handleNavigateToAwardSplit, handleNavigateToAwardResume } = useCardDistribution();

  return (
    <S.ContentContainer
      onClick={() => {
        answered
          ? handleNavigateToAwardResume(bidId, altGroup)
          : handleNavigateToAwardSplit(bidId, altGroup);
      }}
    >
     <S.HeaderContainers>
        <Flex gap={8}>
          {answered ? (
            <S.ImageIcon src={exclamationCircle} alt="exclamation circle" />
          ) : (
            <S.PendingIcon />
          )}
          <p>{altGroup}</p>

          {taxesEmpty && (
            <Tooltip
              placement="rightTop"
              title={t('pages.awardSimulation.tooltipMessages.emptyTaxes')}
            >
              <S.StyledInfoCircle />
            </Tooltip>
          )}
        </Flex>
        <S.Arrow />
      </S.HeaderContainers>
      <S.ContentInside>
        <Flex vertical gap={4}>
          <Flex gap={8} align="center">
            <p>{t('pages.awardSimulation.labels.projects')}</p>
            <Tooltip placement="rightTop" title={projectList?.join(',') || ''}>
              <S.InfoCircle />
            </Tooltip>
          </Flex>
          <p className="title">{totalProjects || '-'}</p>
        </Flex>
        <Flex vertical gap={4}>
          <Flex gap={8} align="center">
            <p>{t('pages.awardSimulation.labels.reviewed')}</p>
          </Flex>
          <p className="title">{handleFormatDate(reviewedIn) || '-'}</p>
        </Flex>
        <Flex vertical gap={4}>
          <Flex gap={8} align="center">
            <p>{t('pages.awardSimulation.labels.demand')}</p>
          </Flex>
          <p className="title">
            {demand ? (
              <Tooltip title={handleFormatValueFields({ number: demand })}>
                {handleFormatValueFields({ number: demand, notation: 'compact' })}
              </Tooltip>
            ) : (
              '-'
            )}
          </p>
        </Flex>
        <Flex vertical gap={4}>
          <Flex gap={8} align="center">
            <p>{t('pages.awardSimulation.labels.extendedNet')}</p>
          </Flex>
          <p className="title">
            {totalNet ? (
              <Tooltip title={handleFormatValueFields({ number: totalNet })}>
                ${handleFormatValueFields({ number: totalNet, notation: 'compact' })}
              </Tooltip>
            ) : (
              '-'
            )}
          </p>
        </Flex>
        <Flex vertical gap={4}>
          <Flex gap={8} align="center">
            <p>{t('pages.awardSimulation.labels.impactSaving')}</p>
          </Flex>
          <Flex gap={8} align="center">
            <S.ImpactLabel className="title">
              {impactSavingPercentage
                ? `${handleFormatValueFields({
                    number: impactSavingPercentage,
                    notation: 'standard',
                  })}%`
                : '-'}
            </S.ImpactLabel>
            <S.ImpactLabel>|</S.ImpactLabel>
            <S.SavingLabel isPositive={handleVerifyValueIsPositive(impactSaving)}>
              {impactSaving ? (
                <Tooltip title={handleFormatValueFields({ number: impactSaving })}>
                  ${handleFormatValueFields({ number: impactSaving, notation: 'compact' })}
                </Tooltip>
              ) : (
                '-'
              )}
            </S.SavingLabel>
          </Flex>
        </Flex>
      </S.ContentInside>
    </S.ContentContainer>
  );
}
