import { BidStatus } from '../../../helpers/enums';
import theme from '../../../styles/theme';
import { ThemeIndicationTag } from './types';

export default function useIndicationTagRqf() {
  const themes: Array<ThemeIndicationTag> = [
    {
      type: BidStatus.ROUND_OPEN,
      backgroundColor: theme.colorsDesignSystem.rfqIndicationTagColors.greenBackground,
      borderColor: theme.colorsDesignSystem.rfqIndicationTagColors.greenFont,
      fontColor: theme.colorsDesignSystem.rfqIndicationTagColors.greenBackground,
    },
    {
      type: BidStatus.CANCELED,
      backgroundColor: theme.colorsDesignSystem.rfqIndicationTagColors.redBackground,
      borderColor: theme.colorsDesignSystem.rfqIndicationTagColors.redFont,
      fontColor: theme.colorsDesignSystem.rfqIndicationTagColors.redBackground,
    },
    {
      type: BidStatus.OPEN,
      backgroundColor: theme.colorsDesignSystem.rfqIndicationTagColors.blueBackground,
      borderColor: theme.colorsDesignSystem.rfqIndicationTagColors.blueFont,
      fontColor: theme.colorsDesignSystem.rfqIndicationTagColors.blueBackground,
    },
    {
      type: BidStatus.CLOSED,
      backgroundColor: theme.colorsDesignSystem.rfqIndicationTagColors.orangeBackground,
      borderColor: theme.colorsDesignSystem.rfqIndicationTagColors.orangeFont,
      fontColor: theme.colorsDesignSystem.rfqIndicationTagColors.orangeBackground,
    },
    {
      type: BidStatus.IN_REVIEW,
      backgroundColor: theme.colorsDesignSystem.rfqIndicationTagColors.purpleBackground,
      borderColor: theme.colorsDesignSystem.rfqIndicationTagColors.purpleFont,
      fontColor: theme.colorsDesignSystem.rfqIndicationTagColors.purpleFont,
    },
    {
      type: BidStatus.DRAFT,
      backgroundColor: theme.colorsDesignSystem.rfqIndicationTagColors.normalBackground,
      borderColor: theme.colorsDesignSystem.rfqIndicationTagColors.normalFont,
      fontColor: theme.colorsDesignSystem.rfqIndicationTagColors.normalFont,
    },
    {
      type: BidStatus.RESULT,
      backgroundColor: theme.colorsDesignSystem.rfqIndicationTagColors.concludedBackground,
      borderColor: theme.colorsDesignSystem.rfqIndicationTagColors.normalFont,
      fontColor: theme.colorsDesignSystem.rfqIndicationTagColors.normalFont,
    },
  ];
  const handleSelectThemeIndicationTagByType = (type: string): ThemeIndicationTag => {
    return themes.find((indicationTagTheme) => indicationTagTheme.type === type) ?? themes[0];
  };

  return {
    handleSelectThemeIndicationTagByType,
  };
}
